import styled from 'styled-components'
import { grey } from 'theme/palette'
import { MOBILE, WIDE_SCREEN } from 'constants/breakpoints'

export const EmployeeItem = styled.div`
  padding: 0.3rem;
  border: solid 1px ${grey};
  margin: 0.3rem;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    font-size: 24px;
  }
`
export const ArchiveStaffsContainer = styled.div`
  max-height: 500px;
  height: 80%;
  overflow: auto;
  @media only screen and (max-width: ${MOBILE}) {
    padding-right: 0.5rem;
  }
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    max-height: 900px;
  }
`
export const SortButton = styled.div`
  cursor: pointer;
  margin-left: 5px;
  margin-bottom: 5px;
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    font-size: 22px;
    margin-left: 10px;
    margin-bottom: 10px;
  }
`
