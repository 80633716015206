import React, { useState, useEffect } from 'react'

function WithFilter(WrappedComponent) {
  return function ProjectWithFilter(props) {
    const { staffs } = props
    const [filteredStaffs, setFilteredStaffs] = useState(staffs)
    const [filter, setFilter] = useState('')
    const [filterFieldValue, setFilterFieldValue] = useState('')

    useEffect(() => {
      setFilteredStaffs(
        filter === ''
          ? staffs
          : staffs.filter(
              staff =>
                staff.firstName.toLowerCase().includes(filter.toLowerCase()) ||
                staff.lastName.toLowerCase().includes(filter.toLowerCase()) ||
                staff.skillStaff?.find(skillStaff =>
                  skillStaff.skill.name
                    .toLowerCase()
                    .includes(filter.toLowerCase())
                ) ||
                staff.englishLevel?.englishLevel
                  .toLowerCase()
                  .includes(filter.toLowerCase())
            )
      )
    }, [staffs, filter])

    return (
      <WrappedComponent
        {...props}
        filterFieldValue={filterFieldValue}
        setFilterFieldValue={setFilterFieldValue}
        setFilter={setFilter}
        filteredStaffs={filteredStaffs}
      />
    )
  }
}

export default WithFilter
