import React from 'react'
import { Field } from 'react-final-form'
import 'antd/dist/antd.css'
import { Form, Input, Button } from 'antd'
import useIsSubmitButtonDisabled from 'hooks/useIsSubmitButtonDisabled'
import { useTranslation } from 'react-i18next'
import { AntdForm, Item, TextArea } from './style'
import Avatar from 'components/UI/Avatar'
import { LAYOUT } from 'constants/staffFormConst'
import { validateOnEmptyTextField } from 'services/validation'
import {
  projectFormFieldLength,
  projectFormTextAreaLength
} from 'constants/projectFormConst'

export const ProjectForm = ({
  handleSubmit,
  getPhotoFile,
  projectData,
  loading
}) => {
  const projectName = projectData?.name
  const description = projectData?.description
  const photoURL = projectData?.photoURL

  const [form, isDisabled, handleValuesChange] = useIsSubmitButtonDisabled(
    projectData ? false : ['name']
  )

  const { t } = useTranslation(['NewProjectCard', 'ValidationMessage'])

  return (
    <AntdForm
      form={form}
      onFieldsChange={handleValuesChange}
      onFinish={handleSubmit}
      {...LAYOUT}
    >
      <Avatar getPhotoFile={getPhotoFile} photoURL={photoURL} />
      <Field name="name">
        {({ input: { onChange, value, name } }) => (
          <Item
            label={t('ProjectName')}
            rules={[
              {
                required: true,
                message: t('ValidationMessage:ProjectNameValidationMessage')
              },
              {
                validator: (rule, value) =>
                  validateOnEmptyTextField({
                    value,
                    message: t('ValidationMessage:FieldCannotBeEmpty')
                  })
              }
            ]}
            name={name}
            initialValue={projectName}
            onChange={event => {
              onChange(event)
            }}
            value={value}
          >
            <Input maxLength={projectFormFieldLength} />
          </Item>
        )}
      </Field>

      <Field name="description">
        {({ input: { onChange, value, name } }) => (
          <Form.Item
            label={t('Description')}
            name={name}
            initialValue={description}
            onChange={onChange}
            value={value}
          >
            <TextArea maxLength={projectFormTextAreaLength} />
          </Form.Item>
        )}
      </Field>

      <Form.Item>
        <Button
          id="addProjectFormBtn"
          type="primary"
          htmlType="submit"
          disabled={isDisabled}
          loading={loading}
        >
          {t('Submit')}
        </Button>
      </Form.Item>
    </AntdForm>
  )
}
