import React from 'react'
import { StaffContainer, StaffWrapper } from './styles'
import StaffItem from '../StaffItem'
import PercentageBar from './PercentageBar'

function VirtualProjectStaffContainer(props) {
  const { staffs, projectName, onDrop } = props

  return (
    <StaffContainer>
      <StaffWrapper>
        {staffs?.map(
          (staff, index) =>
            staff.id && (
              <StaffItem
                projectName={projectName}
                staff={staff}
                key={staff.id}
                indexItem={index}
                onDrop={onDrop}
              >
                <PercentageBar
                  totalPecentage={staff.totalEmploymentPercentage}
                  nullRatePercentage={staff.nullRatePercentage}
                />
              </StaffItem>
            )
        )}
      </StaffWrapper>
    </StaffContainer>
  )
}

export default VirtualProjectStaffContainer
