import { useMutation } from '@apollo/client'
import { Button, Form } from 'antd'
import { DELETE_SKILL } from 'api/skill'
import CancelButton from 'components/CancelButton'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Select } from './styles'

const { Option } = Select

export default function DeleteSkillCard(props) {
  const { closeModal, skills = [], onDeleteSkillSuccess } = props
  const { t } = useTranslation('StaffSkills')

  const [deleteSkill, { loading }] = useMutation(DELETE_SKILL, {
    onCompleted: onDeleteSkillSuccess
  })

  const handleFinish = ({ skillId }) => {
    deleteSkill({ variables: { id: skillId } })
  }

  return (
    <Container>
      <CancelButton closeModal={closeModal} />
      <Form
        key={skills.length}
        name="renameSkillForm"
        layout="vertical"
        onFinish={handleFinish}
        autoComplete="off"
        initialValues={{ skillId: skills[0]?.id }}
      >
        <Form.Item
          name="skillId"
          rules={[{ required: true, message: t('selectRequire') }]}
          label={t('selectRequire')}
        >
          <Select>
            {skills.map(({ id, name }) => (
              <Option key={id}>{name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            {t('submit')}
          </Button>
        </Form.Item>
      </Form>
    </Container>
  )
}
