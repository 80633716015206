import { CheckOutlined, WarningOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { WIDE_SCREEN } from 'constants/breakpoints'

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 260px;
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    width: 350px;
  }
`

export const WarningIcon = styled(WarningOutlined)`
  font-size: 100px;
  margin: 10px;
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    font-size: 160px;
  }
`

export const TextWrapper = styled.div`
  font-size: 18px;
  text-align: center;
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    font-size: 24px;
  }
`

export const ConfirmIcon = styled(CheckOutlined)`
  font-size: 50px;
  margin: 10px;
  cursor: pointer;
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    font-size: 80px;
  }
`
