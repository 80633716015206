import styled from 'styled-components'
import { MOBILE, WIDE_SCREEN } from 'constants/breakpoints'

export const NewProjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  @media only screen and (max-width: ${MOBILE}) {
    margin-top: 0.5rem;
  }
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    margin: 0 15px;
    font-size: 22px;
  }
`
