import styled from 'styled-components'
import { grey } from 'theme/palette'
import { MOBILE, WIDE_SCREEN } from 'constants/breakpoints'

export const ProjectItem = styled.div`
  text-align: center;
  padding: 0.8rem;
  border: solid 1px ${grey};
  margin: 0.3rem;
  cursor: pointer;
  font-size: 16px;
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    font-size: 24px;
  }
`
export const EmployeeContainer = styled.div`
  padding: 0.3rem;
  border: 1px solid ${grey};
  margin: 0.3rem;
`

export const FieldLine = styled.div`
  margin: 0.1rem 0;
`

export const ArchiveProjectsContainer = styled.div`
  max-height: 500px;
  height: 80%;
  overflow-y: auto;
  margin: 0.25rem;
  @media only screen and (max-width: ${MOBILE}) {
    padding-right: 0.5rem;
  }
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    max-height: 900px;
    margin: 0.5rem;
  }
`
