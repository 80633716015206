import React from 'react'
import ProjectsBoard from '../../components/ProjectsBoard'

function Board() {
  return (
    <ProjectsBoard />
  )
}

export default Board
