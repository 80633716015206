import React from 'react'
import SkillButton from '../SkillButton'

const Skills = ({ skills, skillAdd, skillLevelMode }) => {
  return (
    <div>
      {skills?.map(({ id, name, skillStaff }) => (
        <SkillButton
          skillLevelMode={skillLevelMode}
          skillAdd={skillAdd}
          key={id}
          id={id}
          name={name}
          skillStaff={skillStaff}
        />
      ))}
    </div>
  )
}

export default Skills
