export const setAccessToken = newAccessToken => {
  if (!newAccessToken) return
  localStorage.setItem('access-token', newAccessToken)
}

export const setRefreshToken = newRefreshToken => {
  if (!newRefreshToken) return
  localStorage.setItem('refresh-token', newRefreshToken)
}

export const setUserId = id => {
  if (!id) return
  localStorage.setItem('id', id)
}

export const getAccessToken = () => {
  return localStorage.getItem('access-token')
}

export const getRefreshToken = () => {
  return localStorage.getItem('refresh-token')
}

export const getUserId = () => {
  return localStorage.getItem('id')
}

export const removeTokens = () => {
  return (
    localStorage.removeItem('id'),
    localStorage.removeItem('access-token'),
    localStorage.removeItem('refresh-token')
  )
}
