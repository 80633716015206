import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  StaffCard,
  AvailableStaffContainer,
  EmptyAvailableStaffLabel,
  FigureCaption
} from './styles'
import CancelButton from 'components/CancelButton'
import { UserOutlined } from '@ant-design/icons'
import { truncateString } from 'services/truncateString'
import { Avatar } from 'antd'

function AvailableStaffModal(props) {
  const { toggleAvailableStaffModal, availableStaffs } = props
  const { t } = useTranslation('AvailableStaff')

  return (
    <>
      <CancelButton closeModal={toggleAvailableStaffModal} />
      {availableStaffs.length ? (
        <AvailableStaffContainer>
          {availableStaffs.map((current, index) => (
            <StaffCard key={index}>
              <Avatar
                id="avatar"
                shape="square"
                icon={
                  current.photoURL === '' ? (
                    <UserOutlined />
                  ) : (
                    <img src={current.photoURL} alt="icon" />
                  )
                }
              />
              <FigureCaption title={current.firstName}>
                {truncateString(current.firstName)}
              </FigureCaption>
              <FigureCaption title={current.lastName}>
                {truncateString(current.lastName)}
              </FigureCaption>
            </StaffCard>
          ))}
        </AvailableStaffContainer>
      ) : (
        <EmptyAvailableStaffLabel>{t('EmptyLabel')}</EmptyAvailableStaffLabel>
      )}
    </>
  )
}

export default AvailableStaffModal
