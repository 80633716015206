import styled from 'styled-components'
import { WIDE_SCREEN } from 'constants/breakpoints'

export const Field = styled.div`
  display: block;
  text-align: left;
  max-width: 18rem;
  margin: 1rem 0;
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    font-size: 22px;
    margin: 1.25rem 0;
  }
`
export const FieldProject = styled.span`
  word-break: break-word;
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    font-size: 22px;
  }
`
