import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery, useMutation } from '@apollo/client'
import {
  SKILLS,
  ADD_SKILL,
  UPDATE_SKILLS_BY_STAFF,
  RENAME_SKILL
} from 'api/skill'
import { SkillWrapper, ButtonWrapper } from './styles'
import NewSkillCard from './components/NewSkillCard'
import { Button, message } from 'antd'
import CancelButton from '../CancelButton'
import Skills from './components/Skills'
import SkillLevels from './components/SkillLevels'
import RenameSkillCard from './components/RenameSkillCard'
import { AVAILABLE_STAFF } from 'api/staff'
import DeleteSkillCard from './components/DeleteSkillCard'

const SkillCard = ({ staffId, closeModal, skillStaffRefetch }) => {
  const { t } = useTranslation('StaffSkills')
  const [skills, setSkills] = useState([])
  const [isAddSkill, setIsAddSkill] = useState(false)
  const [skillLevelMode, setSkillLevelMode] = useState(null)
  const [skillsToStaff, setSkillsToStaff] = useState([])
  const [isRenameSkill, setIsRenameSkill] = useState(false)
  const [isDeleteSkill, setIsDeleteSkill] = useState(false)

  const { refetch: refetchAvailableStaffs } = useQuery(AVAILABLE_STAFF)
  const { refetch } = useQuery(SKILLS, {
    variables: { staffId },
    onCompleted: data => setSkills(data.getSkills.skills),
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  })
  const [addSkill, { loading }] = useMutation(ADD_SKILL, {
    onCompleted: ({ addSkill }) => {
      if (addSkill.errors.length) {
        message.error(t('addError'))
      } else {
        setIsAddSkillActive(false)
        refetch()
      }
    }
  })
  const [updateSkillStaff, { loading: submitLoading }] = useMutation(
    UPDATE_SKILLS_BY_STAFF,
    {
      onCompleted: () => {
        skillStaffRefetch()
        closeModal()
      }
    }
  )
  const [renameSkill, { loading: renameLoading }] = useMutation(RENAME_SKILL, {
    onCompleted: ({ renameSkill }) => {
      if (renameSkill.errors.length) {
        message.error(t('renameError'))
      } else {
        setIsRenameSkillActive(false)
        refetch()
        refetchAvailableStaffs()
      }
    }
  })
  const handleRenameSkill = ({ skillId, name }) => {
    renameSkill({ variables: { id: skillId, name } })
  }
  const handleNewSkill = ({ skillName }) => {
    addSkill({ variables: { name: skillName } })
  }
  const setIsAddSkillActive = () => {
    setIsAddSkill(!isAddSkill)
  }
  const skillLevelModeChange = value => {
    setSkillLevelMode(value === 'delete' ? null : value)
  }
  const setIsRenameSkillActive = () => {
    setIsRenameSkill(!isRenameSkill)
  }
  const setIsDeleteSkillAcitve = () => setIsDeleteSkill(!isDeleteSkill)

  const skillAdd = id => {
    const skillExist = skillsToStaff.find(skill => skill.skillId === id)
    if (skillsToStaff.length > 0 && skillExist) {
      skillsToStaff.map(
        skill => skill.skillId === id && (skill.level = skillLevelMode)
      )
    } else {
      skillsToStaff.push({ skillId: id, level: skillLevelMode })
    }
    setSkillsToStaff([...skillsToStaff])
  }

  const submit = () => {
    updateSkillStaff({
      variables: { staffId, skills: skillsToStaff }
    })
  }

  const handleDeleteSkillSuccess = () => {
    message.success(t('StaffSkills:deleted'))
    skillStaffRefetch()
    refetch()
    refetchAvailableStaffs()
  }

  useEffect(() => {
    refetch()
  }, [])

  if (isAddSkill)
    return (
      <NewSkillCard
        handleNewSkill={handleNewSkill}
        loading={loading}
        closeModal={setIsAddSkillActive}
      />
    )
  if (isRenameSkill)
    return (
      <RenameSkillCard
        handleRenameSkill={handleRenameSkill}
        skills={skills}
        loading={renameLoading}
        closeModal={setIsRenameSkillActive}
      />
    )
  if (isDeleteSkill)
    return (
      <DeleteSkillCard
        skills={skills}
        closeModal={setIsDeleteSkillAcitve}
        onDeleteSkillSuccess={handleDeleteSkillSuccess}
      />
    )

  return (
    <SkillWrapper>
      <CancelButton closeModal={closeModal} />
      <SkillLevels skillLevelModeChange={skillLevelModeChange} />
      <Skills
        skills={skills}
        skillLevelMode={skillLevelMode}
        skillAdd={skillAdd}
      />
      <ButtonWrapper>
        <Button onClick={setIsAddSkillActive}>{t('add')}</Button>
        <Button onClick={setIsRenameSkillActive}>{t('rename')}</Button>
        <Button onClick={setIsDeleteSkillAcitve}>{t('deleteSkill')}</Button>
        <Button
          disabled={!skillsToStaff.length}
          type="primary"
          loading={submitLoading}
          onClick={submit}
        >
          {t('submit')}
        </Button>
      </ButtonWrapper>
    </SkillWrapper>
  )
}
export default SkillCard
