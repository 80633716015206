import styled from 'styled-components'
import { Button } from 'antd'

export const LevelButtonWrapper = styled.div`
  margin-bottom: 0.5rem;
`

export const LevelModeButton = styled(Button)`
  background-color: ${({ color }) => color};
  margin: 0.1rem;
  :focus {
    background-color: ${({ color }) => color};
    color: black;
  }
`
