import React, { useState } from 'react'
import Modal from 'components/Modal'
import CurrentProjectCard from 'components/CurrentProjectCard'
import ProjectCardContent from '../ProjectCardContent'
import { useQuery } from '@apollo/client'
import { AVAILABLE_STAFF } from 'api/staff'

function ProjectCard({ projectInfo, projectIndex, onDrop }) {
  const { id, rate, staffs } = projectInfo

  const [isOpenProjectCard, setIsOpenProjectCard] = useState(false)

  const { refetch } = useQuery(AVAILABLE_STAFF)

  const handleClick = () => {
    refetch()
    setIsOpenProjectCard(!isOpenProjectCard)
  }

  return (
    <>
      <ProjectCardContent
        {...projectInfo}
        projectIndex={projectIndex}
        handleClick={handleClick}
        onDrop={onDrop}
      />
      <Modal showModal={isOpenProjectCard} closeModal={handleClick}>
        <CurrentProjectCard
          dndState={staffs}
          currentProjectId={id}
          closeModal={handleClick}
          rateForProject={rate}
        />
      </Modal>
    </>
  )
}
export default ProjectCard
