import React from 'react'
import ProjectCards from './components/ProjectCards'
import { useTranslation } from 'react-i18next'

function Dashboard({ loading, error }) {
  const { t } = useTranslation('Error')

  if (loading) return <p>{t('Loading')}</p>
  if (error) return <p>{t('Error')}</p>

  return <ProjectCards />
}

export default Dashboard
