import React, { useState } from 'react'
import { SkillBtn } from './styles'

const SkillButton = ({ id, name, skillLevelMode, skillAdd, skillStaff }) => {
  const [level, setLevel] = useState(skillStaff[0]?.level)
  const handleClick = id => {
    setLevel(skillLevelMode)
    skillAdd(id)
  }
  return (
    <SkillBtn
      disabled={!skillLevelMode && !level}
      level={level}
      mode={skillLevelMode}
      key={id}
      onClick={() => handleClick(id)}
    >
      {name}
    </SkillBtn>
  )
}

export default SkillButton
