import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { message } from 'antd'
import { Form } from 'react-final-form'
import { STAFF, STAFFS, UPDATE_STAFF } from 'api/staff'
import { StaffForm } from 'components/UI/StaffForm'
import Modal from 'components/Modal'
import WarningModal from 'components/WarningModal'

function EditEmployeeFieldForm({ initialValues, setIsEditModeActive }) {
  const employeeId = initialValues.id
  const { t } = useTranslation('EmployeeCard')
  const [updStaff] = useMutation(UPDATE_STAFF, {
    refetchQueries: [
      {
        query: STAFFS
      },
      { query: STAFF, variables: { id: employeeId } }
    ]
  })
  const [currentPhoto, setCurrentPhoto] = useState()
  const [isWarningModalShow, setIsWarningModalShow] = useState(false)
  const [formData, setFormData] = useState({})

  const getPhotoFile = photo => {
    setCurrentPhoto(photo)
  }

  const toggleIsWarningModalShow = values => {
    setFormData(values)
    setIsWarningModalShow(!isWarningModalShow)
  }

  const onSubmit = () => {
    updStaff({
      variables: {
        id: employeeId,
        firstName: formData.firstName.trim(),
        lastName: formData.lastName.trim(),
        photo: currentPhoto,
        description: formData.description?.trim()
      }
    }).then(response => {
      const data = response.data.updStaff

      if (data.staff) message.success(t('EditEmployeeSuccess'))
      else {
        const errorCode = data.errors[0].statusCode

        if (errorCode === '409') return message.error(t('EmployeeAlreadyExists'))
        message.error(t('EmployeeError'))
      }

      setIsEditModeActive()
    })
  }
  return (
    <>
      <Form
        initialValues={initialValues}
        onSubmit={toggleIsWarningModalShow}
        render={({ handleSubmit }) => (
          <StaffForm
            staffData={initialValues}
            getPhotoFile={getPhotoFile}
            handleSubmit={handleSubmit}
          />
        )}
      />
      <Modal
        showModal={isWarningModalShow}
        closeModal={toggleIsWarningModalShow}
      >
        <WarningModal
          closeModal={toggleIsWarningModalShow}
          confirmHandle={onSubmit}
          messageKey={'ConfirmUpdateStaffInfo'}
        />
      </Modal>
    </>
  )
}

export default EditEmployeeFieldForm
