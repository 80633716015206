import React from 'react'
import { useTranslation } from 'react-i18next'
import { AvatarBox, FieldLine, AvatarImg } from './styles'
import emptyUser from 'images/empty-user.svg'
import EmployeeDetailsProjects from '../EmployeeDetailsProjects/index'
import EmployeeDetailsStaffSkills from '../EmployeeDetailsStaffSkills/index'
import { EmployeeDetailsEnglishLevel } from 'components/EmployeeDetailsEnglishLevel'

function EmployeeDetails({
  staffData,
  projectsByStaff,
  staffSkills,
  englishLevel
}) {
  const { t } = useTranslation('StaffCardInArchive')

  return (
    <div>
      <AvatarBox>
        {staffData?.photoURL ? (
          <AvatarImg src={staffData?.photoURL} />
        ) : (
          <AvatarImg src={emptyUser} />
        )}
      </AvatarBox>
      <FieldLine>
        {t('FirstName')} {staffData?.firstName}
      </FieldLine>
      <FieldLine>
        {t('Lastname')} {staffData?.lastName}
      </FieldLine>
      <FieldLine>
        {t('Description')} {staffData?.description}
      </FieldLine>
      <EmployeeDetailsProjects projectsByStaff={projectsByStaff} />
      <EmployeeDetailsStaffSkills staffSkills={staffSkills} />
      <EmployeeDetailsEnglishLevel englishLevel={englishLevel} />
    </div>
  )
}

export default EmployeeDetails
