import styled from 'styled-components'
import { FULL_HD } from 'constants/breakpoints'

export const StaffContainer = styled.div`
  height: 100%;
`

export const StaffWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`

export const CircularBar = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  margin: 5%;
  @media only screen and (min-width: ${FULL_HD}) {
    width: 1.2vw;
    height: 1.2vw;
  }
  .ant-progress-circle .ant-progress-inner {
    background-color: #fff;
  }
`
