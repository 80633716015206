import React from 'react'
import { Field, Wrapper } from './styles'
import { useTranslation } from 'react-i18next'

const EmployeeDetailsStaffSkills = ({ staffSkills }) => {
  const { t } = useTranslation('StaffSkills')
  const lastSkillIndex = staffSkills.length - 1
  return (
    <Wrapper>
      <Field>{t('skills')}</Field>
      {staffSkills?.map(({ level, skill: { name, id } }, index) => (
        <Field level={level} key={id}>
          {name + (index === lastSkillIndex ? '' : ', ')}
        </Field>
      ))}
    </Wrapper>
  )
}

export default EmployeeDetailsStaffSkills
