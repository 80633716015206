import React from 'react'
import { Field } from './styles'
import { useTranslation } from 'react-i18next'

export const EmployeeDetailsEnglishLevel = ({ englishLevel }) => {
  const { t } = useTranslation('EnglishLevel')

  return (
    <Field>
      <Field>
        {t('englishLevel')}
        {englishLevel}
      </Field>
    </Field>
  )
}
