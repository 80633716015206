import styled from 'styled-components'
import { MOBILE, WIDE_SCREEN } from 'constants/breakpoints'
import { Card, Form, Radio } from 'antd'

export const NameLine = styled.div`
  font-size: 15px;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    font-size: 22px;
  }
`

export const NameBox = styled.div`
  margin: 8px;
  max-width: 100%;
`

export const CardGrid = styled(Card.Grid)`
  width: 250px;
  text-align: center;
  padding: 10px;
  margin: 5px 10px 15px;
  @media only screen and (max-width: ${MOBILE}) {
    margin: 8px 5px 10px 0;
  }
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    width: 380px;
  }
`

export const StaffAvatar = styled.img`
  margin: 0 auto;
  height: 150px;
  max-width: 230px;
  object-fit: contain;
  border-radius: 20px;
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    height: 235px;
    max-width: 360px;
  }
`

export const StaffCardLabel = styled.div`
  font-weight: bold;
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    font-size: 22px;
    margin-bottom: 15px;
  }
`

export const AntdForm = styled(Form)`
  .ant-input-number,
  .ant-select {
    width: 60%;
  }
  .ant-row.ant-form-item {
    margin: 10px 0;
  }
  .ant-input-number-input {
    text-align: center;
  }
  .ant-form-item-explain.ant-form-item-explain-error {
    margin-top: 5px;
  }
  button {
    width: 60%;
  }
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    .ant-row.ant-form-item {
      justify-content: center;
    }
    .ant-btn.ant-btn-primary {
      font-size: 22px;
      height: 50px;
      margin: 0.5rem 0;
    }
    .ant-form-item-explain.ant-form-item-explain-error {
      font-size: 21px;
    }
    input {
      font-size: 24px;
    }
    svg {
      width: 14px;
      height: 14px;
    }
  }
`

export const AntdRadioGroup = styled(Radio.Group)`
  width: 100%;
`

export const AntdRadioButton = styled(Radio.Button)`
  width: 70px;
`
