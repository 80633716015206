import { useMutation, useQuery } from '@apollo/client'
import { ARCH_PROJECT, PROJECT, PROJECTS_IN_ARCHIVE } from 'api/project'
import React, { useState } from 'react'
import { message } from 'antd'
import { BottomBar, ButtonInProjectMenu, CancelButton } from './styles'
import { useTranslation } from 'react-i18next'
import EditProjectCard from 'components/EditProjectCard'
import Modal from 'components/Modal'
import WarningModal from 'components/WarningModal'

function ProjectMenuDetails(props) {
  const {
    projectId,
    toggleShowAddStaffModal,
    toggleShowStaffsModal,
    toggleShowMenuModal
  } = props
  const { t } = useTranslation(['ProjectMenuDetails', 'Error'])
  const [isEditProject, setIsEditProject] = useState(false)
  const [isWarningModalShow, setIsWarningModalShow] = useState(false)
  const { loading, error, data } = useQuery(PROJECT, {
    variables: { id: projectId }
  })

  const [archProject] = useMutation(ARCH_PROJECT)

  const addProjectInArchive = () => {
    archProject({
      variables: {
        id: projectId,
        archived: true
      },
      refetchQueries: [{ query: PROJECTS_IN_ARCHIVE }]
    })
      .then(response => {
        if (response.data.archProject.errors.length) {
          response.data.archProject.errors.forEach(error => {
            message.error(error.message)
          })
        }
        message.success(t('ProjectMenuDetails:ArchivedProjectSuccess'))
      })
      .catch(() => {})
  }

  const toggleIsEditProject = () => {
    setIsEditProject(!isEditProject)
  }

  const toggleIsWarningModalShow = () => {
    setIsWarningModalShow(!isWarningModalShow)
  }

  const project = data?.project.project

  if (loading) return <p>{t('Loading')}</p>
  if (error) return <p>{t('Error')}</p>

  return (
    <>
      <CancelButton closeModal={toggleShowMenuModal} />
      <BottomBar>
        <ButtonInProjectMenu onClick={toggleShowAddStaffModal}>
          {t('AddStaffsTitle')}
        </ButtonInProjectMenu>
        <ButtonInProjectMenu onClick={toggleIsEditProject}>
          {t('Edit')}
        </ButtonInProjectMenu>
        <ButtonInProjectMenu onClick={toggleIsWarningModalShow}>
          {t('Archive')}
        </ButtonInProjectMenu>
        <ButtonInProjectMenu onClick={toggleShowStaffsModal}>
          {t('Staff')}
        </ButtonInProjectMenu>
      </BottomBar>
      <Modal showModal={isEditProject} closeModal={toggleIsEditProject}>
        <EditProjectCard project={project} editProject={toggleIsEditProject} />
      </Modal>
      <Modal
        showModal={isWarningModalShow}
        closeModal={toggleIsWarningModalShow}
      >
        <WarningModal
          closeModal={toggleIsWarningModalShow}
          confirmHandle={addProjectInArchive}
          messageKey={'ConfirmArchiveProject'}
        />
      </Modal>
    </>
  )
}

export default ProjectMenuDetails
