import React from 'react'
import { Select } from 'antd'

function EmploymentPercentageSelect(props) {
  const { handleChange, employmentPercentages } = props

  return (
    <Select
      defaultValue={employmentPercentages[0]}
      onChange={handleChange}
      {...props}
    >
      {employmentPercentages?.map(employmentPercentage => (
        <Select.Option
          key={employmentPercentage}
          value={employmentPercentage}
        >{`${employmentPercentage}%`}</Select.Option>
      ))}
    </Select>
  )
}

export default EmploymentPercentageSelect
