import styled from 'styled-components'
import { lightBlack, white } from 'theme/palette'
import { FULL_HD } from 'constants/breakpoints'

export const Employee = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 140px;
  color: ${lightBlack};
  border-radius: 5px;
  padding-bottom: 5px;
  box-shadow: 0 1px 3px rgba(9, 30, 66, 0.25);
  margin: 0.15rem 0rem;
  background-color: ${white};
  border: 1px solid rgba(9, 30, 66, 0.25);
  position: relative;
  div {
    cursor: pointer;
  }

  ${props => (props.$isDragging ? 'visibility: hidden' : '')};
`

export const EmployeeAvatar = styled.div`
  background: url(${({ photoURL }) => photoURL}) no-repeat;
  background-position: 50% 25%;
  background-size: 100% auto;
  margin: 3%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

export const StretchWrapper = styled.div`
  width: 100%;
  padding-top: 100%;
  position: relative;
  min-height: 100px;
`

export const NameLine = styled.span`
  font-weight: bold;
  font-size: 0.8rem;
  @media only screen and (min-width: ${FULL_HD}) {
    font-size: 0.75vw;
  }
`

export const EmployeeInfoContainer = styled.div`
  overflow: hidden;
`
