export const LAYOUT = {
  labelCol: {
    span: 10
  },
  wrapperCol: {
    span: 16
  }
}

export const projectFormFieldLength = 50
export const projectFormTextAreaLength = 255
