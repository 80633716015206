import React from 'react'
import { useTranslation } from 'react-i18next'
import CancelButton from 'components/CancelButton'
import { ContentWrapper, ConfirmIcon, TextWrapper, WarningIcon } from './styles'

function WarningModal(props) {
  const { closeModal, confirmHandle, messageKey } = props
  const { t } = useTranslation('WarningMessage')
  return (
    <ContentWrapper>
      <CancelButton closeModal={closeModal} />
      <WarningIcon />
      <TextWrapper>{t(messageKey)}</TextWrapper>
      <ConfirmIcon onClick={confirmHandle} />
    </ContentWrapper>
  )
}

export default WarningModal
