import styled from 'styled-components'
import { WIDE_SCREEN } from 'constants/breakpoints'

export const EditEmployeeCardTitle = styled.div`
  text-align: center;
  margin-top: 0.5rem;
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    font-size: 22px;
  }
`
