import React from 'react'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import {
  AVAILABLE_STAFF,
  STAFF_UPDATED,
  STAFF_ARCHIVED,
  STAFF_ADDED
} from 'api/staff'
import ProjectCardContent from '../ProjectCardContent'
import WithFilter from 'components/WithFilter'
import { useSubscriptionForRefetch } from 'hooks/useSubscriptionForRefetch'

import { SKILLS_TO_STAFF_ADDED } from 'api/skill'
import {
  PROJECT_STAFF_UPDATED,
  STAFF_ADDED_BY_PROJECT,
  STAFF_DELETED_BY_PROJECT,
  STAFF_REPLACED
} from 'api/project'
const ProjectCardContentWithFilter = WithFilter(ProjectCardContent)

function VirtualProjectCard(props) {
  const { projectName, onDrop } = props
  const { t } = useTranslation('Error')
  const { data: staffsData, error, loading, refetch } = useQuery(
    projectName === 'AvailableStaffs' && AVAILABLE_STAFF,
    {
      fetchPolicy: 'cache-and-network'
    }
  )

  useSubscriptionForRefetch(STAFF_REPLACED, refetch)
  useSubscriptionForRefetch(STAFF_ADDED_BY_PROJECT, refetch)
  useSubscriptionForRefetch(STAFF_DELETED_BY_PROJECT, refetch)
  useSubscriptionForRefetch(PROJECT_STAFF_UPDATED, refetch)

  useSubscriptionForRefetch(STAFF_ADDED, refetch)
  useSubscriptionForRefetch(STAFF_ARCHIVED, refetch)
  useSubscriptionForRefetch(STAFF_UPDATED, refetch)
  useSubscriptionForRefetch(SKILLS_TO_STAFF_ADDED, refetch)

  if (loading && !staffsData) return <p>{t('Loading')}</p>
  if (error) return <p>{t('Error')}</p>

  const staffs =
    staffsData[projectName === 'AvailableStaffs' && 'availableStaff'].staffs

  return (
    projectName === 'AvailableStaffs' && (
      <ProjectCardContentWithFilter
        name={projectName}
        staffs={staffs}
        isVirtual
        onDrop={onDrop}
      />
    )
  )
}

export default VirtualProjectCard
