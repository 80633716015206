import React from 'react'
import 'antd/dist/antd.css'
import { Form, Input, Button } from 'antd'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { AntdForm, Item, TextArea } from './style'
import useIsSubmitButtonDisabled from 'hooks/useIsSubmitButtonDisabled'
import Avatar from 'components/UI/Avatar'
import {
  LAYOUT,
  staffFormFieldLength,
  staffFormTextAreaLength
} from 'constants/staffFormConst'
import { validateOnEmptyTextField } from 'services/validation'

export const StaffForm = ({
  staffData,
  handleSubmit,
  getPhotoFile,
  loading
}) => {
  const firstName = staffData?.firstName
  const lastName = staffData?.lastName
  const description = staffData?.description
  const photoURL = staffData?.photoURL

  const [form, isDisabled, handleValuesChange] = useIsSubmitButtonDisabled(
    staffData ? false : ['firstName', 'lastName']
  )

  const { t } = useTranslation(['NewEmployeeCard', 'ValidationMessage'])

  return (
    <AntdForm
      form={form}
      onFinish={handleSubmit}
      onFieldsChange={handleValuesChange}
      {...LAYOUT}
    >
      <Avatar getPhotoFile={getPhotoFile} photoURL={photoURL} />
      <Field name="firstName">
        {({ input: { onChange, value, name } }) => (
          <Item
            label={t('FirstName')}
            name={name}
            onChange={e => onChange(e)}
            initialValue={firstName}
            value={value}
            rules={[
              {
                required: true,
                message: t('ValidationMessage:FirstNameValidationMessage')
              },
              {
                validator: (rule, value) =>
                  validateOnEmptyTextField({
                    value,
                    message: t('ValidationMessage:FieldCannotBeEmpty')
                  })
              }
            ]}
          >
            <Input maxLength={staffFormFieldLength} />
          </Item>
        )}
      </Field>
      <Field name="lastName">
        {({ input: { onChange, value, name } }) => (
          <Item
            label={t('LastName')}
            name={name}
            onChange={e => onChange(e)}
            initialValue={lastName}
            value={value}
            rules={[
              {
                required: true,
                message: t('ValidationMessage:LastNameValidationMessage')
              },
              {
                validator: (rule, value) =>
                  validateOnEmptyTextField({
                    value,
                    message: t('ValidationMessage:FieldCannotBeEmpty')
                  })
              }
            ]}
          >
            <Input maxLength={staffFormFieldLength} />
          </Item>
        )}
      </Field>
      <Field name="description">
        {({ input: { onChange, value, name } }) => (
          <Form.Item
            label={t('Description')}
            name={name}
            onChange={onChange}
            initialValue={description}
            value={value}
          >
            <TextArea maxLength={staffFormTextAreaLength} />
          </Form.Item>
        )}
      </Field>

      <Form.Item>
        <Button
          id="staffFormBtn"
          type="primary"
          htmlType="submit"
          disabled={isDisabled}
          loading={loading}
        >
          {t('Submit')}
        </Button>
      </Form.Item>
    </AntdForm>
  )
}
