export const LAYOUT = {
  labelCol: {
    span: 8
  },
  wrapperCol: {
    span: 16
  }
}

export const staffFormFieldLength = 50
export const staffFormTextAreaLength = 255