import React from 'react'
import { StyledInput } from './styles'

function SearchInput({ search, setSearch }) {
  const handleChangeInput = e => {
    const searchValue = e.target.value
    setSearch(searchValue)
  }

  return (
    <StyledInput
      allowClear
      onChange={e => handleChangeInput(e)}
      value={search}
      onSearch={value => setSearch(value)}
    />
  )
}

export default SearchInput
