import React from 'react'
import { FieldLine, RightSideButtons, Line, ProjectName } from './styles'
import { useTranslation } from 'react-i18next'
import { Button, Tooltip } from 'antd'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'

const EmployeePortfolio = ({ projects, onAddPortfolioClick, onRenameProjectFromPortfolio, onDeleteProjectFromPortfolio }) => {
  const { t } = useTranslation(['EmployeeCard', 'NewProjectCard', 'ProjectPortfolio'])
  return (
    <FieldLine>
      {projects.length ? (
        projects.map(({ id, name }) => (
          <Line key={id}>
            <Tooltip placement="topLeft" title={name}>
              <ProjectName>{name}</ProjectName>
            </Tooltip>
            <RightSideButtons>
              <Tooltip placement="topLeft" title={t('ProjectPortfolio:EdinProjectName')}>
                <EditOutlined className="icon" onClick={() => onRenameProjectFromPortfolio({projectId: id})} />
              </Tooltip>
              <Tooltip placement="topLeft" title={t('ProjectPortfolio:DeleteProject')}>
                <DeleteOutlined className="icon" onClick={() => onDeleteProjectFromPortfolio({projectId: id})} />
              </Tooltip>
            </RightSideButtons>
          </Line>
        ))
      ) : (
        <p>{t('EmployeeCard:EmptyPortfolio')} </p>
      )}
      <Button type="primary" onClick={onAddPortfolioClick}>
        {t('NewProjectCard:AddProject')}
      </Button>
    </FieldLine>
  )
}

export default EmployeePortfolio
