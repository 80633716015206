import React, { useMemo, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import EmploymentPercentageSelect from 'components/EmploymentPercentageSelect'
import { WIDE_SCREEN } from 'constants/breakpoints'
import useIsSubmitButtonDisabled from 'hooks/useIsSubmitButtonDisabled'
import useRateValidationRules from 'hooks/useRateValidationRules'
import { RATE_MIN_VALUE, RATE_MAX_VALUE } from 'constants/rateFormConst'
import {
  MAX_EMPLOYMENT_PERCENTAGE,
  MIN_EMPLOYMENT_PERCENTAGE
} from 'constants/employmentPercentageConst'
import { useTranslation } from 'react-i18next'
import { Button, InputNumber } from 'antd'
import emptyUser from 'images/empty-user.svg'
import {
  NameLine,
  NameBox,
  CardGrid,
  StaffAvatar,
  StaffCardLabel,
  AntdForm,
  AntdRadioGroup,
  AntdRadioButton
} from './style'
import { getEmploymentPercentages } from 'services/employmentPercentage'

export const StaffCard = ({
  photoURL,
  firstName,
  lastName,
  onClick,
  id,
  totalEmploymentPercentage
}) => {
  const handleClick = ({ rate, employmentPercentage }) => {
    onClick(id, +rate, employmentPercentage)
  }

  const employmentPercentages = getEmploymentPercentages(
    totalEmploymentPercentage > 0
      ? MAX_EMPLOYMENT_PERCENTAGE - totalEmploymentPercentage
      : MAX_EMPLOYMENT_PERCENTAGE
  )

  const [currentEmployment, setCurrentEmpoyment] = useState(
    MAX_EMPLOYMENT_PERCENTAGE
  )
  const { t } = useTranslation('AddStaffInProject')
  const fullTimeStatus = totalEmploymentPercentage === MIN_EMPLOYMENT_PERCENTAGE
  const rules = useRateValidationRules()
  const [form, isDisabled, handleValuesChange] = useIsSubmitButtonDisabled([
    'rate'
  ])
  const isWideScreen = useMediaQuery({
    query: `(min-width: ${WIDE_SCREEN})`
  })

  const handleChange = newValue => {
    setCurrentEmpoyment(newValue)
    form.setFieldsValue({
      employmentPercentage: newValue
    })
  }

  const isCurrentEmploumentFull = useMemo(() => {
    return currentEmployment === MAX_EMPLOYMENT_PERCENTAGE
  }, [currentEmployment])

  return (
    <CardGrid>
      <StaffAvatar src={photoURL ? photoURL : emptyUser} />
      <NameBox>
        <NameLine>{firstName}</NameLine>
        <NameLine>{lastName}</NameLine>
      </NameBox>
      <AntdForm
        form={form}
        onFieldsChange={handleValuesChange}
        onFinish={handleClick}
        initialValues={{
          fullTime: fullTimeStatus,
          employmentPercentage:
            MAX_EMPLOYMENT_PERCENTAGE - totalEmploymentPercentage
        }}
      >
        <StaffCardLabel>{t('Rate')}</StaffCardLabel>
        <AntdForm.Item name="rate" validateFirst rules={rules}>
          <InputNumber
            type='number'
            size={isWideScreen ? 'large' : 'middle'}
            max={RATE_MAX_VALUE}
            min={RATE_MIN_VALUE}
          />
        </AntdForm.Item>
        <StaffCardLabel>{t('WorkTime')}</StaffCardLabel>
        <AntdForm.Item name="fullTime">
          <AntdRadioGroup>
            <AntdRadioButton
              value={true}
              disabled={!fullTimeStatus | !isCurrentEmploumentFull}
            >
              {t('FullWorkTime')}
            </AntdRadioButton>
            <AntdRadioButton value={false}>{t('PartWorkTime')}</AntdRadioButton>
          </AntdRadioGroup>
        </AntdForm.Item>
        <StaffCardLabel>{t('EmploymentPercentage')}</StaffCardLabel>
        <AntdForm.Item name="employmentPercentage">
          <EmploymentPercentageSelect
            handleChange={handleChange}
            employmentPercentages={employmentPercentages}
          />
        </AntdForm.Item>
        <AntdForm.Item>
          <Button disabled={isDisabled} htmlType="submit" type="primary">
            {t('SubmitButtonLabel')}
          </Button>
        </AntdForm.Item>
      </AntdForm>
    </CardGrid>
  )
}
