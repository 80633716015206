import styled from 'styled-components'
import { MOBILE, WIDE_SCREEN } from 'constants/breakpoints'
import CancelBtn from 'components/CancelButton'

export const Button = styled.button`
  display: inline-block;
  padding: 0.5rem 1em;
  margin-top: 5px;
  cursor: pointer;
  border: solid 2px #009999;
  text-decoration: none;
  background: #f7f7f7;
  width: 100%;
  height: 60px;
  border-left: solid 6px #009999;
  color: black;
  font-size: 16px;
  font-weight: normal;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.29);
  &:active {
    box-shadow: inset 0 0 2px rgba(128, 128, 128, 0.1);
    transform: translateY(2px);
  }
  @media only screen and (max-width: ${MOBILE}) {
    margin: 0.3rem;
    width: 250px;
  }
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    font-size: 26px;
    margin-top: 15px;
    height: 100px;
  }
`

export const ArchiveStaffContainer = styled.div`
  margin: 0.25rem;
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    margin: 0.5rem;
  }
`

export const CancelButton = styled(CancelBtn)`
  margin-bottom: 1rem;
`
