import React from 'react'
import EditProjectFieldForm from './components/EditProjectFieldForm'
import { useTranslation } from 'react-i18next'
import CancelButton from '../CancelButton'
import { Label } from './style'

function EditProjectCard(props) {
  const { editProject, project } = props
  const { t } = useTranslation('NewProjectCard')
  const { id, name, description, photoURL } = project
  const initialValues = {
    id,
    name,
    description,
    photoURL
  }
  return (
    <>
      <CancelButton closeModal={editProject} />
      <div>
        <Label>{t('EditProject')}</Label>
        <EditProjectFieldForm
          initialValues={initialValues}
          editProject={editProject}
        />
      </div>
    </>
  )
}

export default EditProjectCard
