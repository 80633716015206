import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { PROJECTS_IN_ARCHIVE } from 'api/project'
import { ProjectItem, FieldLine, ArchiveProjectsContainer } from './styles'
import ProjectDetailsInArchive from './ProjectDetailsInArchive'
import Modal from 'components/Modal'

function ArchiveProjects() {
  const { loading, error, data } = useQuery(PROJECTS_IN_ARCHIVE, {
    fetchPolicy: 'network-only'
  })
  const { t } = useTranslation('Error')
  const [ProjectDetailsIsOpen, setProjectDetailsIsOpen] = useState(false)
  const [currentProjectInfo, setCurrentProjectInfo] = useState([])

  function toggleProjectDetailsInArchive(data) {
    setProjectDetailsIsOpen(!ProjectDetailsIsOpen)
    setCurrentProjectInfo(data)
  }

  if (loading) return <p>{t('Loading')}</p>
  if (error) return <p>{t('Error')}</p>

  const archiveProjects = data.projectsArchive.projects

  if (archiveProjects.length === 0)
    return <div>{t('ProjectArchiveIsEmpty')}</div>

  return (
    <>
      <ArchiveProjectsContainer>
        {archiveProjects.map(project => (
          <ProjectItem
            key={project.id}
            onClick={() => toggleProjectDetailsInArchive(project)}
          >
            <FieldLine>{project.name}</FieldLine>
          </ProjectItem>
        ))}
      </ArchiveProjectsContainer>
      <Modal
        showModal={ProjectDetailsIsOpen}
        closeModal={toggleProjectDetailsInArchive}
      >
        <ProjectDetailsInArchive
          projectInfo={currentProjectInfo}
          closeModal={toggleProjectDetailsInArchive}
        />
      </Modal>
    </>
  )
}
export default ArchiveProjects
