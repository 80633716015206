import { useState } from 'react'
import { Form } from 'antd'
import { checkIsButtonDisabled } from 'services/validation'

function useIsSubmitButtonDisabled(fieldsToTouchValidate) {
  const [form] = Form.useForm()
  const [isDisabled, setIsDisabled] = useState(Boolean(fieldsToTouchValidate))
  const handleValuesChange = () =>
    setIsDisabled(checkIsButtonDisabled(form, fieldsToTouchValidate))
  return [form, isDisabled, handleValuesChange]
}
export default useIsSubmitButtonDisabled
