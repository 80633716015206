import { gql } from '@apollo/client'

export const ADD_PROJECT = gql`
  mutation addProject(
    $name: String!
    $photo: Upload
    $description: String
    $archive: Boolean
  ) {
    addProject(
      data: {
        name: $name
        description: $description
        photo: $photo
        archive: $archive
      }
    ) {
      project {
        id
        name
        description
        rate
        archive
        createdAt
        updatedAt
        photoURL
      }
      errors {
        message
        statusCode
      }
    }
  }
`

export const ARCH_PROJECT = gql`
  mutation archProject($id: String!, $archived: Boolean!) {
    archProject(id: $id, archived: $archived) {
      project {
        id
        name
        description
        rate
        photoURL
      }
      errors {
        message
        statusCode
      }
    }
  }
`

export const DEL_PROJECT = gql`
  mutation delProject($id: String!) {
    delProject(id: $id) {
      project {
        id
        name
      }
      errors {
        message
        statusCode
      }
    }
  }
`

export const STAFFS_ON_PROJECT = gql`
  query staffsOnProject($rateSort: String!, $projectId: String!) {
    staffsOnProject(rateSort: $rateSort, projectId: $projectId) {
      staffs {
        id
        firstName
        lastName
        photoURL
        description
        archive
        createdAt
        updatedAt
        totalEmploymentPercentage
        staffsConnection {
          projectId
          rateStaff
          employmentPercentage
          id
        }
      }
      errors {
        message
        statusCode
      }
    }
  }
`

export const GET_PROJECTS = gql`
  query projects($searchValue: String) {
    projects(searchValue: $searchValue) {
      projects {
        id
        name
        photoURL
        description
        rate
        archive
        createdAt
        updatedAt
        projectConnection {
          rateStaff
          id
          employmentPercentage
          staff {
            id
            firstName
            lastName
            photoURL
            description
            archive
            createdAt
            updatedAt
          }
        }
      }
      errors {
        message
        statusCode
      }
    }
  }
`

export const PROJECT = gql`
  query project($id: String!) {
    project(id: $id) {
      project {
        id
        name
        description
        photoURL
        rate
      }
    }
  }
`

export const ADD_STAFF_BY_PROJECT = gql`
  mutation addStaffByProject(
    $staffId: String!
    $projectId: String!
    $rateStaff: Float!
    $employmentPercentage: Float!
  ) {
    addStaffByProject(
      data: {
        staffId: $staffId
        projectId: $projectId
        rateStaff: $rateStaff
        employmentPercentage: $employmentPercentage
      }
    ) {
      projectStaff {
        id
        projectId
        staffId
        rateStaff
        employmentPercentage
        staff {
          id
          firstName
          lastName
          photoURL
          description
          archive
          createdAt
          updatedAt
        }
        project {
          rate
        }
      }

      errors {
        message
        statusCode
      }
    }
  }
`

export const DEL_STAFF_FROM_PROJECT = gql`
  mutation delStaffByProject($projectId: String!, $staffId: String!) {
    delStaffByProject(projectId: $projectId, staffId: $staffId) {
      projectStaff {
        rateStaff
        projectId
        staffId
        id
        project {
          rate
        }
      }
      errors {
        message
        statusCode
      }
    }
  }
`

export const REPLACE_STAFF_FROM_PROJECT = gql`
  mutation replaceStaffByProject(
    $rateStaff: Float!
    $employmentPercentage: Float!
    $oldProjectId: String!
    $newProjectId: String!
    $staffId: String!
  ) {
    replaceStaffByProject(
      rateStaff: $rateStaff
      employmentPercentage: $employmentPercentage
      oldProjectId: $oldProjectId
      newProjectId: $newProjectId
      staffId: $staffId
    ) {
      projectStaffs {
        rateStaff
        projectId
        staffId
        employmentPercentage
        project {
          rate
        }
      }
      errors {
        message
        statusCode
      }
    }
  }
`

export const MOVE_STAFF_FROM_VIRTUAL_PROJECT = gql`
  mutation moveStaffFromVirtualProject(
    $rateStaff: Float!
    $employmentPercentage: Float!
    $projectId: String!
    $staffId: String!
  ) {
    moveStaffFromVirtualProject(
      rateStaff: $rateStaff
      employmentPercentage: $employmentPercentage
      projectId: $projectId
      staffId: $staffId
    ) {
      projectStaff {
        rateStaff
        projectId
        staffId
        employmentPercentage
        project {
          rate
        }
      }
      errors {
        message
        statusCode
      }
    }
  }
`

export const UPDATE_PROJECT = gql`
  mutation updProject(
    $id: String!
    $name: String!
    $photo: Upload
    $description: String
  ) {
    updProject(
      data: { name: $name, description: $description, photo: $photo }
      id: $id
    ) {
      project {
        id
        name
        photoURL
        description
      }
      errors {
        message
        statusCode
      }
    }
  }
`
export const PROJECTS_IN_ARCHIVE = gql`
  query {
    projectsArchive {
      projects {
        id
        name
        description
      }
      errors {
        message
        statusCode
      }
    }
  }
`
export const UPDATE_PROJECT_STAFF = gql`
  mutation updateProjectStaff(
    $staffId: String!
    $projectId: String!
    $updatingField: UpdateProjectStaffInput!
  ) {
    updateProjectStaff(
      staffId: $staffId
      projectId: $projectId
      updatingField: $updatingField
    ) {
      projectStaff {
        rateStaff
        employmentPercentage
        project {
          rate
        }
      }
      errors {
        message
        statusCode
      }
    }
  }
`

export const DEL_ARCHIVE_STAFF_FROM_PROJECTS = gql`
  mutation delArchiveStaffByProjects($staffId: String!) {
    delArchiveStaffByProjects(staffId: $staffId) {
      projectStaffs {
        projectId
        staffId
        rateStaff
        project {
          rate
        }
      }
      errors {
        message
        statusCode
      }
    }
  }
`

export const STAFF_REPLACED = gql`
  subscription {
    staffReplaced {
      projectStaffs {
        rateStaff
        projectId
        staffId
        employmentPercentage
        staff {
          id
          firstName
          lastName
          photoURL
          description
          archive
          createdAt
          updatedAt
        }
        project {
          rate
        }
      }
    }
  }
`

export const STAFF_ADDED_BY_PROJECT = gql`
  subscription {
    staffAddedByProject {
      projectStaff {
        id
        projectId
        staffId
        rateStaff
        employmentPercentage
        staff {
          id
          firstName
          lastName
          photoURL
          description
          archive
          createdAt
          updatedAt
        }
        project {
          rate
        }
      }
    }
  }
`

export const STAFF_DELETED_BY_PROJECT = gql`
  subscription {
    staffDeletedByProject {
      projectStaff {
        rateStaff
        projectId
        staffId
        id
        project {
          rate
        }
      }
    }
  }
`

export const PROJECT_STAFF_UPDATED = gql`
  subscription {
    projectStaffUpdated {
      projectStaff {
        projectId
        staffId
        rateStaff
        employmentPercentage
        project {
          rate
        }
      }
    }
  }
`

export const ARCHIVE_STAFF_BY_PROJECT_DELETED = gql`
  subscription {
    archiveStaffByProjectDeleted {
      projectStaffs {
        projectId
        staffId
        rateStaff
        project {
          rate
        }
      }
    }
  }
`

export const PROJECT_UPDATED = gql`
  subscription {
    projectUpdated {
      project {
        id
        name
        photoURL
        description
      }
    }
  }
`

export const PROJECT_ADDED = gql`
  subscription {
    projectAdded {
      project {
        id
        name
        description
        rate
        archive
        createdAt
        updatedAt
        photoURL
      }
    }
  }
`

export const PROJECT_ARCHIVED = gql`
  subscription {
    projectArchived {
      project {
        id
        name
        description
        rate
        photoURL
        archive
      }
    }
  }
`
export const PROJECTS_BY_STAFF = gql`
  query projectsByStaff($id: String!) {
    projectsByStaff(id: $id) {
      projects {
        id
        name
      }
    }
  }
`
export const PROJECTS_IN_PORTFOLIO = gql`
  query projectsByStaffInPortfolio($id: String!) {
    projectsByStaffInPortfolio(id: $id) {
      projects {
        id
        name
      }
    }
  }
`

export const ADD_PROJECT_PORTFOLIO = gql`
  mutation addPortfolioProject($name: String!, $staffId: String!) {
    addPortfolioProject(name: $name, staffId: $staffId) {
      project {
        id
        name
      }
      errors {
        message
        statusCode
      }
    }
  }
`

export const DELETE_PROJECT_PORTFOLIO = gql`
  mutation deleteProjectPortfolio($projectId: String!, $staffId: String!) {
    deleteProjectPortfolio(projectId: $projectId, staffId: $staffId) {
      project {
        id
        name
        projectConnection {
          rateStaff
          id
          employmentPercentage
        }
      }
      errors {
        message
        statusCode
      }
    }
  }
`

export const RENAMING_PROJECT_PORTFOLIO = gql`
  mutation renamingPortfolioProject($projectId: String!, $name: String!) {
    renamingPortfolioProject(projectId: $projectId, name: $name) {
      project {
        id
        name
      }
      errors {
        message
        statusCode
      }
    }
  }
`

export const RENAMED_PROJECT_PORTFOLIO = gql`
  subscription {
    renamedPortfolioProject {
      project {
        id
        name
      }
    }
  }
`
