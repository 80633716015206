import styled from 'styled-components'
import { WIDE_SCREEN } from 'constants/breakpoints'
import { grey } from 'theme/palette'

export const EmployeeItem = styled.div`
  display: flex;
  width: 300px;
  height: 60px;
  border: solid 1px ${grey};
  margin: 0.3rem;
  font-size: 16px;
  text-align: center;
  align-items: center;
  font-family: 'Supermolot', 'sans-serif';
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    width: 500px;
    height: 120px;
    font-size: 24px;
    margin: 0.8rem;
  }
`
export const RightSideButtons = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: 4px;
  .icon {
    font-size: 15px;
    margin: 4px;
  }
  .icon:hover {
    color: red;
  }
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    #edit {
      font-size: 28px;
      margin: 0.5rem;
    }
    #delete {
      font-size: 28px;
      margin: 0.5rem;
    }
  }
`

export const AvatarContainer = styled.div`
  float: left;
  margin: 5px 10px 5px 5px;
  #avatar {
    float: left;
  }
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    margin: 10px 15px 10px 10px;
  }
`
export const StaffListContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 500px;
  margin-top: 10px;
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    max-height: 900px;
    margin-bottom: 10px;
  }
`

export const NameField = styled.div`
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
