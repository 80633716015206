import React from 'react'
import { Field, FieldProject } from './styles'
import { useTranslation } from 'react-i18next'

const EmployeeDetailsProjects = ({ projectsByStaff }) => {
  const { t } = useTranslation('EmployeeCard')
  return (
    <Field>
      <FieldProject>{t('Projects')}</FieldProject>
      {projectsByStaff?.map(({ id, name }, index) => (
        <FieldProject key={id}>
          {name + (index !== projectsByStaff.length - 1 ? ', ' : '')}
        </FieldProject>
      ))}
    </Field>
  )
}

export default EmployeeDetailsProjects
