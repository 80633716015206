import React from 'react'
import { Button, Input, Form } from 'antd'
import CancelButton from '../../../CancelButton'
import { FormWrapper } from './styles'
import { useTranslation } from 'react-i18next'

const NewSkillCard = ({ handleNewSkill, loading, closeModal }) => {
  const { t } = useTranslation('StaffSkills')

  return (
    <FormWrapper>
      <CancelButton closeModal={closeModal} />
      <Form
        name="basic"
        layout="vertical"
        onFinish={handleNewSkill}
        autoComplete="off"
      >
        <Form.Item
          label={t('labelNewSkill')}
          name="skillName"
          rules={[
            { required: true, message: t('formRequire') },
            { whitespace: true, message: t('formRequire') }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            {t('submit')}
          </Button>
        </Form.Item>
      </Form>
    </FormWrapper>
  )
}

export default NewSkillCard
