import styled from 'styled-components'
import Masonry from 'react-masonry-css'

export const MasonryContainer = styled(Masonry)`
  display: flex;
  flex: 1 0 auto;
  padding: 0rem 0.15rem;
  margin-bottom: 0.3rem;
  .my-masonry-grid_column {
    display: flex;
    flex-flow: column nowrap;
  }
`
