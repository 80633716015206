import styled from 'styled-components'
import { WIDE_SCREEN } from 'constants/breakpoints'

export const Label = styled.div`
  font-weight: 600;
  letter-spacing: 1px;
  text-align: center;
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    font-size: 22px;
    margin-top: 10px;
  }
`
