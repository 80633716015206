import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function useStaffSort() {
  const [sortField, setSortField] = useState('lastName')
  const { t } = useTranslation('StaffListSort')

  const sortClick = () => {
    setSortField(sortField === 'lastName' ? 'firstName' : 'lastName')
  }

  const staffListWithSort = (lastName, firstName) => {
    return sortField === 'lastName'
      ? `${lastName} ${firstName}`
      : `${firstName} ${lastName}`
  }

  const sortLabel =
    sortField === 'lastName' ? t('SortTypeFirstName') : t('SortTypeLastName')

  return [sortField, sortClick, staffListWithSort, sortLabel]
}
