import styled from 'styled-components'
import { MOBILE, WIDE_SCREEN } from 'constants/breakpoints'

export const AvatarBox = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
`
export const AvatarImg = styled.img`
  margin: 0 auto;
  width: 150px;
  border-radius: 20px;
`

export const FieldLine = styled.div`
  margin: 1rem 0;
`

export const EmployeeCardWrapper = styled.div`
  margin: 1rem;
  max-width: 300px;
  @media screen and (min-width: ${WIDE_SCREEN}) {
    max-width: 450px;
  }
  @media only screen and (max-width: ${MOBILE}) {
    margin: 0.25rem;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  button {
    margin: 0.5rem 0;
  }
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    button {
      font-size: 22px;
      margin: 0.75rem 0;
    }
    .ant-btn.ant-btn-primary {
      height: 50px;
    }
  }
`
export const BaseButton = styled.button`
  cursor: pointer;
`
