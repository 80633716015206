import React from 'react'
import { STAFF } from 'api/staff'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { WIDE_SCREEN } from 'constants/breakpoints'
import useIsSubmitButtonDisabled from 'hooks/useIsSubmitButtonDisabled'
import useRateValidationRules from 'hooks/useRateValidationRules'
import { RATE_MIN_VALUE, RATE_MAX_VALUE } from 'constants/rateFormConst'
import { ButtonStyle, InputNum, AntdForm, FormItem } from './styles'
import EmploymentPercentageSelect from 'components/EmploymentPercentageSelect'
import { MAX_EMPLOYMENT_PERCENTAGE } from 'constants/employmentPercentageConst'
import { getEmploymentPercentages } from 'services/employmentPercentage'

function EditProjectStaffForm(props) {
  const {
    detailsData: { projectId, oldProjectId, rateStaff },
    detailsTypes,
    staffId,
    handleClick,
    replacedStaff
  } = props
  const { data: staffData, loading, error } = useQuery(STAFF, {
    variables: { id: staffId },
    fetchPolicy: 'cache-and-network'
  })
  const { t } = useTranslation(['Dashboard', 'Error'])
  const [form, isDisabled, handleValuesChange] = useIsSubmitButtonDisabled(
    false
  )
  const rules = useRateValidationRules()
  const isWideScreen = useMediaQuery({
    query: `(min-width: ${WIDE_SCREEN})`
  })

  if (loading) return <p>{t('Error:Loading')}</p>
  if (error) return <p>{t('Error:Error')}</p>

  const staff = staffData?.staff?.staff
  const staffConnections = staff?.staffsConnection || []

  const project = staffConnections.find(sc =>
    oldProjectId ? sc.projectId === oldProjectId : sc.projectId === projectId
  )

  const availablePercentage =
    MAX_EMPLOYMENT_PERCENTAGE -
    staff.totalEmploymentPercentage +
    (project?.employmentPercentage || 0)

  const employmentPercentages = getEmploymentPercentages(availablePercentage)

  const handleChange = newValue => {
    form.setFieldsValue({
      employmentPercentage: newValue
    })
  }

  return (
    <AntdForm
      form={form}
      onFinish={handleClick}
      onFieldsChange={handleValuesChange}
      replacedstaff={replacedStaff}
      initialValues={{
        rate: detailsTypes.includes('employmentPercentage') ? null : rateStaff,
        employmentPercentage: replacedStaff
          ? availablePercentage
          : project?.employmentPercentage
      }}
    >
      {detailsTypes.includes('rateStaff') ? (
        <FormItem
          label={t('Rate')}
          name="rate"
          validateFirst={true}
          rules={rules}
        >
          <InputNum
            type="number"
            size={isWideScreen ? 'large' : 'middle'}
            min={RATE_MIN_VALUE}
            max={RATE_MAX_VALUE}
          />
        </FormItem>
      ) : null}
      {detailsTypes.includes('employmentPercentage') ? (
        <FormItem name="employmentPercentage" label={t('Employment')}>
          <EmploymentPercentageSelect
            employmentPercentages={employmentPercentages}
            handleChange={handleChange}
          />
        </FormItem>
      ) : null}
      <AntdForm.Item>
        <ButtonStyle htmlType="submit" type="primary" disabled={isDisabled}>
          Ok
        </ButtonStyle>
      </AntdForm.Item>
    </AntdForm>
  )
}

export default EditProjectStaffForm
