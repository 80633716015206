import React from 'react'
import ReactModal from 'react-modal'
import { useMediaQuery } from 'react-responsive'
import { LAPTOP_OR_DESKTOP } from 'constants/breakpoints'
import './index.css'

function NewEmployeeCard({ children, showModal, closeModal, backStateToPrev }) {
  const isMobile = useMediaQuery({ maxDeviceWidth: LAPTOP_OR_DESKTOP })
  const closeHandle = () => {
    closeModal()
    backStateToPrev && backStateToPrev()
  }
  return (
    showModal && (
      <ReactModal
        ariaHideApp={false}
        className={isMobile ? 'modalMobile' : 'modal'}
        overlayClassName="overlay"
        contentLabel="Modal"
        isOpen
        closeTimeoutMS={200}
        onRequestClose={closeHandle}
        shouldCloseOnEsc={true}
        shouldFocusAfterRender={true}
        shouldReturnFocusAfterClose={true}
      >
        {children}
      </ReactModal>
    )
  )
}

export default NewEmployeeCard
