export const updateProject = (project, projectStaffData) => {
  const updatedProject = project.map(staff => {
    if (staff.staff?.id === projectStaffData.staffId) {
      return {
        ...staff,
        rateStaff: projectStaffData.rateStaff,
        employmentPercentage: projectStaffData.employmentPercentage
      }
    } else {
      return staff
    }
  })

  return updatedProject
}
