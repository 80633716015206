import { Upload } from 'antd'
import styled from 'styled-components'
import { MOBILE, WIDE_SCREEN } from 'constants/breakpoints'

export const UploadPhoto = styled(Upload)`
  .ant-upload.ant-upload-select-picture-card {
    width: 200px;
    height: 200px;
    margin: 20px 0;
  }
  .ant-upload.ant-upload-select.ant-upload-select-picture-card {
    align-self: center;
  }
  @media only screen and (max-width: ${MOBILE}) {
    .ant-upload.ant-upload-select-picture-card {
      margin: 15px 0 0;
    }
  }
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    margin-bottom: 20px;
    .ant-upload.ant-upload-select-picture-card {
      width: 350px;
      height: 350px;
    }
  }
`
export const Image = styled.div`
  width: 100%;
  height: 100%;
  background: url(${props => props.imageUrl || props.photoURL}) no-repeat center;
  background-size: contain;
`
export const Div = styled.div`
  div {
    margin-top: 8px;
  }
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    svg {
      width: 25px;
      height: 25px;
    }
    div {
      font-size: 22px;
    }
  }
`
