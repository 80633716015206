import React, { useState } from 'react'
import { Form as FinalForm } from 'react-final-form'
import { useMutation } from '@apollo/client'
import { ADD_STAFF, STAFFS } from 'api/staff'
import { Container } from './styles'
import { StaffForm } from 'components/UI/StaffForm'
import { message } from 'antd'
import { useTranslation } from 'react-i18next'

function EmployeeCardFieldForm({ closeModal }) {
  const [addStaff, { loading }] = useMutation(ADD_STAFF)
  const [state, setState] = useState({ photo: '' })
  const { t } = useTranslation('EmployeeCard')
  const getPhotoFile = photo => {
    setState({
      photo: photo
    })
  }
  const onSubmit = ({ firstName, lastName, description }) => {
    addStaff({
      variables: {
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        description: description?.trim(),
        photo: state.photo
      },
      refetchQueries: [{ query: STAFFS }]
    }).then(response => {
      closeModal()

      const data = response.data.addStaff

      if (data.staff) message.success(t('AddEmployeeSuccess'))
      else {
        const errorCode = data.errors[0].statusCode

        if (errorCode === '409') return message.error(t('EmployeeAlreadyExists'))
        message.error(t('EmployeeError'))
      }
    })
  }

  return (
    <Container>
      <FinalForm
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <StaffForm
            getPhotoFile={getPhotoFile}
            handleSubmit={handleSubmit}
            loading={loading}
          />
        )}
      />
    </Container>
  )
}

export default EmployeeCardFieldForm
