import React from 'react'
import { Button, Input, Form } from 'antd'
import CancelButton from '../../../CancelButton'
import { FormWrapper } from './styles'
import { useTranslation } from 'react-i18next'

export default function ProjectPortfolioCard({
  initailValues,
  handleNewProjectForPortfolio,
  closeModal
}) {
  const { t } = useTranslation('NewProjectCard')
  return (
    <FormWrapper>
      <CancelButton closeModal={closeModal} />
      <Form
        name="basic"
        layout="vertical"
        onFinish={handleNewProjectForPortfolio}
        autoComplete="off"
        initialValues={initailValues}
      >
        <Form.Item
          label={initailValues ? t('EditProjectName') : t('AddProject')}
          name="projectName"
          rules={[{ required: true, message: t('formRequire') }]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t('Submit')}
          </Button>
        </Form.Item>
      </Form>
    </FormWrapper>
  )
}
