export const levelButtons = [
  { level: 'delete', color: 'white' },
  { level: 'LOW', color: '#D53032' },
  { level: 'MIDDLE', color: '#df9c00' },
  { level: 'HIGH', color: '#11b300' }
]
export const levelColor = {
  null: 'white',
  LOW: '#D53032',
  MIDDLE: '#df9c00',
  HIGH: '#11b300'
}
