import React from 'react'
import { EmptyStaffWrapper, EmptyStaffButton, EmptyStaffTitle } from './styles'
import { useTranslation } from 'react-i18next'

function EmptyStaffMenu(props) {
  const { toggleShowAddStaffModal } = props
  const { t } = useTranslation('ProjectCardStaffDetails')

  return (
    <EmptyStaffWrapper>
      <EmptyStaffTitle>{t('EmtyStaffLabel')}</EmptyStaffTitle>
      <EmptyStaffButton onClick={toggleShowAddStaffModal}>
        {t('AddStaff')}
      </EmptyStaffButton>
    </EmptyStaffWrapper>
  )
}

export default EmptyStaffMenu
