import styled from 'styled-components'
import { levelColor } from 'constants/skillConst'
import { WIDE_SCREEN } from 'constants/breakpoints'

export const Field = styled.span`
  max-width: 100%;
  word-break: break-word;
  color: ${({ level }) => levelColor[level]};
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    font-size: 22px;
  }
`
export const Wrapper = styled.div`
  max-width: 100%;
`
