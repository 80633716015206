import React from 'react'
import { Button, Input, Form } from 'antd'
import CancelButton from 'components/CancelButton'
import { useTranslation } from 'react-i18next'
import { Select } from './styles'

const RenameSkillCard = ({
  skills,
  closeModal,
  handleRenameSkill,
  loading
}) => {
  const { Option } = Select
  const { t } = useTranslation('StaffSkills')

  return (
    <div>
      <CancelButton closeModal={closeModal} />
      <Form
        name="renameSkillForm"
        layout="vertical"
        onFinish={handleRenameSkill}
        autoComplete="off"
      >
        <Form.Item
          name="skillId"
          rules={[{ required: true, message: t('selectRequire') }]}
          label={t('selectRequire')}
        >
          <Select>
            {skills.map(({ id, name }) => (
              <Option key={id}>{name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="name"
          rules={[
            { required: true, message: t('formRequire') },
            { whitespace: true, message: t('formRequire') }
          ]}
          label={t('formRequire')}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            {t('submit')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default RenameSkillCard
