import styled from 'styled-components'
import { MOBILE, WIDE_SCREEN } from 'constants/breakpoints'
import { Button, InputNumber, Form } from 'antd'

export const AntdForm = styled(Form)`
  text-align: center;
  .ant-form {
    display: flex;
    flex-direction: column;
  }
  .ant-form-item .ant-select {
    width: 100%;
  }
  .ant-form-item-control-input-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .ant-row {
    margin-bottom: 10px;
  }
  .ant-form-item-explain.ant-form-item-explain-error {
    margin-top: 5px;
  }
  .ant-form-item-label {
   width: ${props => (props.replacedStaff ? '38%' : 'auto')};
  }
  @media only screen and (max-width: ${MOBILE}) {
    .ant-form-item-label {
      padding: 0 0 4px;
    }
    .ant-row {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    .ant-form-item-required {
      font-size: 24px;
    }
    .ant-form-item-explain.ant-form-item-explain-error {
      font-size: 22px;
    }
  }
  width: 100%;
`

export const ButtonStyle = styled(Button)`
  width: 50px;
  @media only screen and (max-width: ${MOBILE}) {
    margin-top: 12px;
  }
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    font-size: 24px;
    width: 100px;
    height: 47px;
    margin-bottom: 10px;
    margin-top: 12px;
  }
`

export const FormItem = styled(Form.Item)`
  margin-top: 15px;
`

export const InputNum = styled(InputNumber)`
  width: 100%;
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    input {
      font-size: 24px;
    }
    svg {
      width: 14px;
      height: 14px;
    }
  }
`
