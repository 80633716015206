import React from 'react'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { message } from 'antd'
import { UPDATE_PROJECT_STAFF, STAFFS_ON_PROJECT } from 'api/project'
import EditProjectStaffForm from 'components/EditProjectStaffForm'
import CancelButton from 'components/CancelButton'
import { AVAILABLE_STAFF } from 'api/staff'

function EditProjectStaffDetailsModal(props) {
  const { closeModal, projectId, currentStaff } = props
  const [updateProjectStaff] = useMutation(UPDATE_PROJECT_STAFF)
  const { t } = useTranslation('ProjectCardStaffDetails')
  const handleClick = ({ rate, employmentPercentage }) => {
    updateProjectStaff({
      variables: {
        staffId: currentStaff.id,
        projectId,
        updatingField: {
          type: currentStaff.detailsType,
          [currentStaff.detailsType]: rate ?? employmentPercentage
        }
      },
      refetchQueries: [
        {
          query: STAFFS_ON_PROJECT,
          variables: {
            rateSort: 'asc',
            projectId: projectId
          }
        },
        { query: AVAILABLE_STAFF }
      ]
    }).then(() => {
      message.success(
        rate
          ? t('EditStaffRateSuccess')
          : t('EditStaffEmploymentPercentageSuccess')
      )
    })
    closeModal()
  }

  return (
    <>
      <CancelButton closeModal={closeModal} />
      <EditProjectStaffForm
        detailsData={currentStaff.detailsData}
        detailsTypes={[currentStaff.detailsType]}
        staffId={currentStaff.id}
        handleClick={handleClick}
      />
    </>
  )
}

export default EditProjectStaffDetailsModal
