export const englishLevels = [
  { name: 'Beginner', value: 'BEGINNER' },
  { name: 'Elementary', value: 'ELEMENTARY' },
  { name: 'Pre-intermediate', value: 'PREINTERMEDIATE' },
  { name: 'Low Intermediate', value: 'LOW_INTERMEDIATE' },
  { name: 'Intermediate', value: 'INTERMEDIATE' },
  { name: 'Upper Intermediate', value: 'UPPER_INTERMEDIATE' },
  { name: 'Pre-advanced', value: 'PREADVANCE' },
  { name: 'Advanced', value: 'ADVANCED' },
  { name: 'Very Advanced', value: 'VERY_ADVANCED' }
]
