import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import { useTranslation } from 'react-i18next'
import StaffList from 'components/StaffList'
import NewEmployeeCard from 'components/NewEmployeeCard'
import ArchiveStaffs from '../ArchivesContainer/ArchiveStaffs'
import { Container } from './styles'
import CancelButton from 'components/CancelButton'

function StaffContainer(props) {
  const { t } = useTranslation('StaffContainer')
  const { closeModal } = props

  return (
    <>
      <CancelButton closeModal={closeModal} />
      <Container>
        <Tabs>
          <TabList>
            <Tab>{t('Staffs')}</Tab>
            <Tab>{t('NewEmployee')}</Tab>
            <Tab>{t('Archive')}</Tab>
          </TabList>
          <TabPanel>
            <StaffList />
          </TabPanel>
          <TabPanel>
            <NewEmployeeCard closeModal={closeModal} />
          </TabPanel>
          <TabPanel>
            <ArchiveStaffs />
          </TabPanel>
        </Tabs>
      </Container>
    </>
  )
}

export default StaffContainer
