import React from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { Row, Col, message } from 'antd'
import { ADD_STAFF_BY_PROJECT, STAFFS_ON_PROJECT } from 'api/project'
import { StaffList } from './styles'
import CancelButton from 'components/CancelButton'
import { StaffCard } from 'components/UI/StaffCard'
import { MAX_EMPLOYMENT_PERCENTAGE } from 'constants/employmentPercentageConst'

function AddStaffInProject(props) {
  const { t } = useTranslation([
    'Dashboard',
    'Error',
    'AddStaffInProject',
    'AvailableStaff'
  ])
  const { closeModal, currentProjectId, availableStaff, refetch } = props

  const [addStaffByProject] = useMutation(ADD_STAFF_BY_PROJECT)

  const { error, loading, data } = useQuery(STAFFS_ON_PROJECT, {
    variables: {
      projectId: currentProjectId,
      rateSort: 'asc'
    },
    fetchPolicy: 'network-only'
  })

  if (loading) return <p>{t('Error:Loading')}</p>
  if (error) return <p>{t('Error:Error')}</p>

  const currentStaffsIds = data?.staffsOnProject.staffs.map(staff => staff.id)
  const possibleStaffs = availableStaff.filter(
    ({ id }) => currentStaffsIds.indexOf(id) === -1
  )

  const handleClick = (id, rate, employmentPercentage) => {
    addStaffByProject({
      variables: {
        staffId: id,
        projectId: currentProjectId,
        rateStaff: rate,
        employmentPercentage
      },
      refetchQueries: [
        {
          query: STAFFS_ON_PROJECT,
          variables: { rateSort: 'asc', projectId: currentProjectId }
        }
      ]
    }).then(() => {
      refetch()
      message.success(t('AddStaffInProject:SuccessfulAddition'))
    })
  }

  return (
    <>
      <CancelButton closeModal={closeModal} />
      <StaffList>
        <Row className="row">
          {possibleStaffs.length ? (
            possibleStaffs.map(
              ({
                id,
                firstName,
                lastName,
                photoURL,
                totalEmploymentPercentage
              }) =>
                totalEmploymentPercentage !== MAX_EMPLOYMENT_PERCENTAGE && (
                  <Col key={id}>
                    <StaffCard
                      id={id}
                      photoURL={photoURL}
                      firstName={firstName}
                      lastName={lastName}
                      totalEmploymentPercentage={totalEmploymentPercentage}
                      onClick={handleClick}
                    />
                  </Col>
                )
            )
          ) : (
            <p>{t('AvailableStaff:NoAvailableStaffForProject')}</p>
          )}
        </Row>
      </StaffList>
    </>
  )
}

export default AddStaffInProject
