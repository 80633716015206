import styled from 'styled-components'
import { WIDE_SCREEN } from 'constants/breakpoints'

export const FieldLine = styled.div`
  text-align: center;
  word-break: break-word;
  margin: 1rem 0;
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    font-size: 22px;
  }
`

export const BottomBar = styled.div`
  display: flex;
  justify-content: space-around;
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    .ant-btn.ant-btn-primary {
      font-size: 22px;
      height: 50px;
    }
  }
`
