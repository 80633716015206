import styled from 'styled-components'
import { WIDE_SCREEN } from 'constants/breakpoints'

export const AvatarBox = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
`
export const AvatarImg = styled.img`
  margin: 0 auto;
  width: 150px;
  border-radius: 20px;
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    width: 250px;
  }
`
export const FieldLine = styled.div`
  text-align: left;
  margin: 1rem 0;
  word-break: break-word;
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    font-size: 22px;
    margin: 1.25rem 0;
  }
`
