import React from 'react'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import {
  AVAILABLE_STAFF,
  STAFF_ADDED,
  STAFF_ARCHIVED,
  STAFF_UPDATED
} from 'api/staff'
import {
  STAFF_REPLACED,
  STAFF_ADDED_BY_PROJECT,
  STAFF_DELETED_BY_PROJECT,
  PROJECT_STAFF_UPDATED
} from 'api/project'
import { useSubscriptionForRefetch } from 'hooks/useSubscriptionForRefetch'

function AvailableStaff(props) {
  const { setAvailableStaffs, toggleAvailableStaffModal } = props
  const { t } = useTranslation(['AvailableStaff', 'Error'])
  const { data, loading, error, refetch } = useQuery(AVAILABLE_STAFF)

  useSubscriptionForRefetch(STAFF_ADDED, refetch)
  useSubscriptionForRefetch(STAFF_ARCHIVED, refetch)
  useSubscriptionForRefetch(STAFF_UPDATED, refetch)
  useSubscriptionForRefetch(STAFF_REPLACED, refetch)
  useSubscriptionForRefetch(STAFF_ADDED_BY_PROJECT, refetch)
  useSubscriptionForRefetch(STAFF_DELETED_BY_PROJECT, refetch)
  useSubscriptionForRefetch(PROJECT_STAFF_UPDATED, refetch)

  if (loading) return <p>{t('Error:Loading')}</p>
  if (error) return <p>{t('Error:Error')}</p>

  const availableStaff = data?.availableStaff?.staffs

  const handleClick = () => {
    setAvailableStaffs(availableStaff)
    toggleAvailableStaffModal()
  }

  return (
    <div onClick={handleClick}>
      {`${t('AvailableStaff')} (${availableStaff.length})`}
    </div>
  )
}

export default AvailableStaff
