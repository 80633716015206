import { Progress } from 'antd'
import { MAX_EMPLOYMENT_PERCENTAGE } from 'constants/employmentPercentageConst'
import React from 'react'
import { CircularBar } from '../styles'

function PercentageBar(props) {
  const { nullRatePercentage = 0, totalPecentage = 0 } = props

  const availablePercent =
    MAX_EMPLOYMENT_PERCENTAGE - totalPecentage + nullRatePercentage

  return (
    0 < availablePercent &&
    availablePercent < MAX_EMPLOYMENT_PERCENTAGE && (
      <CircularBar>
        <Progress type="circle" percent={availablePercent} width={23} />
      </CircularBar>
    )
  )
}

export default PercentageBar
