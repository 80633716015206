import { gql } from '@apollo/client'

export const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(input: { email: $email, password: $password }) {
      access_token
      refresh_token
      id
      errors {
        message
        statusCode
      }
    }
  }
`

export const REFRESH = gql`
  mutation refresh($id: String!, $refresh: String!) {
    refresh(id: $id, refresh: $refresh) {
      access_token
      refresh_token
      errors {
        message
        statusCode
      }
    }
  }
`
