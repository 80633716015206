import React, { useState } from 'react'
import { Button, ArchiveStaffContainer, CancelButton } from './styles'
import { useTranslation } from 'react-i18next'
import Modal from 'components/Modal'
import ArchiveProjects from './ArchiveProjects'
import ArchiveStaffs from './ArchiveStaffs'

function Archive({ closeModal }) {
  const { t } = useTranslation(['ArchiveContainer'])
  const [ArchiveProjectsIsOpen, setArchiveProjectsIsOpen] = useState(false)
  const [ArchiveStaffsIsOpen, setArchiveStaffsIsOpen] = useState(false)

  function toggleArchiveProjectsModal() {
    setArchiveProjectsIsOpen(!ArchiveProjectsIsOpen)
  }

  function toggleArchiveStaffsModal() {
    setArchiveStaffsIsOpen(!ArchiveStaffsIsOpen)
  }

  return (
    <>
      <CancelButton closeModal={closeModal} />
      <Button onClick={toggleArchiveProjectsModal}>
        {t('ArchiveProjects')}
      </Button>
      <Button onClick={toggleArchiveStaffsModal}>{t('ArchiveStaffs')}</Button>
      <Modal
        showModal={ArchiveProjectsIsOpen}
        closeModal={toggleArchiveProjectsModal}
      >
        <CancelButton closeModal={toggleArchiveProjectsModal} />
        <ArchiveProjects />
      </Modal>
      <Modal
        showModal={ArchiveStaffsIsOpen}
        closeModal={toggleArchiveStaffsModal}
      >
        <ArchiveStaffContainer>
          <CancelButton closeModal={toggleArchiveStaffsModal} />
          <ArchiveStaffs />
        </ArchiveStaffContainer>
      </Modal>
    </>
  )
}

export default Archive
