import React from 'react'
import { LevelButtonWrapper, LevelModeButton } from './styles'
import { useTranslation } from 'react-i18next'
import { levelButtons } from 'constants/skillConst'

const SkillLevels = ({ skillLevelModeChange }) => {
  const { t } = useTranslation('StaffSkills')

  return (
    <LevelButtonWrapper>
      {levelButtons.map(({ level, color }) => (
        <LevelModeButton
          key={level}
          color={color}
          level={level}
          onClick={() => skillLevelModeChange(level)}
        >
          {t(level)}
        </LevelModeButton>
      ))}
    </LevelButtonWrapper>
  )
}

export default SkillLevels
