import React, { useState } from 'react'
import MediaQuery from 'react-responsive'
import { Dropdown } from 'antd'
import { LAPTOP_OR_DESKTOP } from 'constants/breakpoints'
import { MobileMenuIcon } from './styles'
import { NavMenu } from '../../styles'

function MobileMenu({ navMenu }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const handleMenuClick = e => {
    if (e.key !== '5') setIsMenuOpen(!isMenuOpen)
  }

  const handleVisibleChange = flag => setIsMenuOpen(flag)

  const mobileNavMenu = (
    <NavMenu onClick={handleMenuClick} selectable={false}>
      {navMenu}
    </NavMenu>
  )

  return (
    <MediaQuery maxWidth={LAPTOP_OR_DESKTOP}>
      <Dropdown
        overlay={mobileNavMenu}
        visible={isMenuOpen}
        onVisibleChange={handleVisibleChange}
        trigger={['click']}
        arrow={true}
      >
        <MobileMenuIcon />
      </Dropdown>
    </MediaQuery>
  )
}

export default MobileMenu
