import styled from 'styled-components'
import { MOBILE, WIDE_SCREEN } from 'constants/breakpoints'

export const StaffButton = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  @media only screen and (max-width: ${MOBILE}) {
    #staffBtn {
      font-size: 0.9rem;
    }
  }
`

export const Avatar = styled.div`
  height: 200px;
  width: 300px;
  border-radius: 10px;
  padding: 1rem;
  background: url(${props => props.photoURL}) no-repeat center;
  background-size: contain;
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    width: 450px;
    height: 300px;
  }
`
export const FieldLine = styled.div`
  font-family: 'Supermolot', 'sans-serif';
  margin: 1rem 0;
  max-width: 100%;
  word-break: break-word;
  @media only screen and (max-width: ${MOBILE}) {
    font-size: 0.9rem;
  }
  span {
    cursor: pointer;
    :hover {
      color: #3851ad;
    }
  }
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    font-size: 22px;
    margin: 1.5rem 0;
  }
`
export const BottomBar = styled.div`
  font-family: 'Supermolot', 'sans-serif';
  display: flex;
  justify-content: space-between;
  align-content: space-between;
`

export const ControlButtons = styled.div`
  display: flex;
  flex-direction: row;
  #menu {
    font-size: 35px;
    margin-right: 5px;
  }
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    svg {
      width: 50px;
      height: 50px;
    }
  }
`

export const Container = styled.div`
  max-width: 300px;
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    margin: 1rem;
    max-width: 450px;
  }
`
