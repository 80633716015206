import { useTranslation } from 'react-i18next'
import { workTypeCheck } from 'utils'

export default function useMoveStaff() {
  const { t } = useTranslation('ValidationMessage')

  const moveStaff = ({
    sourceProject,
    destinationProject,
    staff,
    dndState
  }) => {
    if (sourceProject === destinationProject) {
      return {}
    }

    let sourceProjectIndex = dndState.findIndex(
      project => project.id === sourceProject
    )
    if (sourceProjectIndex === -1) sourceProjectIndex = null

    const destinationProjectIndex = dndState.findIndex(
      project => project.id === destinationProject
    )

    const projectStaff = dndState[
      destinationProjectIndex
    ]?.projectConnection?.find(item => item.staff?.id === staff.id)

    if (projectStaff) {
      return { error: t('StaffOnProject') }
    }

    if (workTypeCheck(staff.totalEmploymentPercentage)) {
      return { error: t('FullEmployment') }
    }

    return projectStaff ?? {}
  }

  return moveStaff
}
