import styled from 'styled-components'

export const SkillWrapper = styled.div`
  max-width: 40rem;
  padding: 0.5rem;
`
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  flex-wrap: wrap;
  gap: 10px;
`
