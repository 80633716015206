import styled from 'styled-components'
import { Button } from 'antd'
import { levelColor } from 'constants/skillConst'

export const SkillBtn = styled(Button)`
  background-color: ${({ level }) => levelColor[level]};
  margin: 0.1rem;
  max-width: 100%;

  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
  :hover {
    background-color: ${({ mode }) => levelColor[mode]};
    color: black;
  }
  :focus {
    background-color: ${({ level }) => levelColor[level]};
    color: black;
  }
`
