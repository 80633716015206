import { gql } from '@apollo/client'

export const SKILLS = gql`
  query getSkills($staffId: String!) {
    getSkills(staffId: $staffId) {
      skills {
        id
        name
        skillStaff {
          id
          level
        }
      }
    }
  }
`
export const ADD_SKILL = gql`
  mutation addSkill($name: String!) {
    addSkill(name: $name) {
      skill {
        id
        name
      }
      errors {
        message
        statusCode
      }
    }
  }
`
export const SKILLS_BY_STAFF = gql`
  query getSkillsByStaff($staffId: String!) {
    getSkillsByStaff(staffId: $staffId) {
      skillStaff {
        id
        level
        skill {
          id
          name
        }
      }
    }
  }
`
export const UPDATE_SKILLS_BY_STAFF = gql`
  mutation updateSkillStaff($staffId: String!, $skills: [SkillStaffInput!]!) {
    updateSkillStaff(staffId: $staffId, skills: $skills) {
      status
      errors {
        message
        statusCode
      }
    }
  }
`
export const SKILLS_TO_STAFF_ADDED = gql`
  subscription {
    skillStaffAdded {
      status
      errors {
        message
        statusCode
      }
    }
  }
`
export const RENAME_SKILL = gql`
  mutation renameSkill($id: String!, $name: String!) {
    renameSkill(id: $id, name: $name) {
      skill {
        id
        name
      }
      errors {
        message
        statusCode
      }
    }
  }
`
export const DELETE_SKILL = gql`
  mutation deleteSkill($id: String!) {
    deleteSkill(id: $id) {
      errors {
        message
        statusCode
      }
    }
  }
`
