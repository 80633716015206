import React from 'react'
import { CancelWrapper, CancelIcon } from './styles'
import closeIcon from 'images/close-icon.svg'

function CancelButton(props) {
  const { closeModal, backStateToPrev } = props
  const handleClick = () => {
    backStateToPrev && backStateToPrev()
    closeModal()
  }

  return (
    <CancelWrapper className={props?.className}>
      <CancelIcon src={closeIcon} alt={'close icon'} onClick={handleClick} />
    </CancelWrapper>
  )
}

export default CancelButton
