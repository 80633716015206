import styled from 'styled-components'
import { MOBILE, WIDE_SCREEN } from 'constants/breakpoints'

export const StaffList = styled.div`
  margin: 1rem;
  max-height: 550px;
  max-width: 950px;
  overflow-y: auto;
  overflow-x: hidden;
  .row {
    align-items: center;
  }
  .ant-col {
    flex: 0 0 250px;
  }

  @media only screen and (max-width: ${MOBILE}) {
    margin: 8px 0 0 0;
    padding: 0 10px;
  }

  @media only screen and (min-width: ${WIDE_SCREEN}) {
    margin: 1.5rem;
    max-height: 900px;
    max-width: 1250px;
    .ant-col {
      flex: 0 0 380px;
    }
  }
`
