import { Menu } from 'antd'
import styled from 'styled-components'
import { LAPTOP_OR_DESKTOP, WIDE_SCREEN } from 'constants/breakpoints'

export const Container = styled.div`
  display: flex;
  padding: 5px 10%;
  border-bottom: 1px solid #1e6176;
  background: rgb(19, 55, 67);
`

export const LogoImg = styled.img`
  width: 70px;
  height: 70px;
`

export const NavContainer = styled.div`
  display: flex;
  margin: auto;
  @media (min-width: ${LAPTOP_OR_DESKTOP}px) {
    margin-left: 100px;
  }
  @media (max-width: ${LAPTOP_OR_DESKTOP}px) {
    margin-right: 0px;
  }
  .ant-menu-root.ant-menu-vertical,
  .ant-menu-root.ant-menu-vertical-left,
  .ant-menu-root.ant-menu-vertical-right,
  .ant-menu-root.ant-menu-inline {
    display: inline-grid;
    width: 78vw;
    grid-template-columns: repeat(4, auto) 17% 7% 11%;
  }
`

export const NavMenu = styled(Menu)`
  background-color: rgb(19, 55, 67);
  color: white;
  display: flex;
  border-right: none;
  @media (max-width: ${LAPTOP_OR_DESKTOP}px) {
    display: block;
    background-color: white;
  }

  #logout {
    position: fixed;
    font-size: 18px;
    color: white;
    width: 200px;
    right: 0;
  }

  #logout:hover {
    font-weight: bold;
  }
`

export const MenuStaffItem = styled(Menu.Item)`
  font-size: 18px;
  justify-self: center;
  grid-column-start: 0;
  grid-column-start: 1;
  :active {
    background: none;
  }
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    font-size: 28px;
  }
`
export const MenuAddItem = styled(MenuStaffItem)`
  grid-column-start: 1;
  grid-column-start: 2;
`
export const MenuArchivesItem = styled(MenuStaffItem)`
  margin-right: 2rem;
  grid-column-start: 2;
  grid-column-start: 3;
`
export const MenuAvailableStaffItem = styled(MenuStaffItem)`
  margin-right: 3rem;
  grid-column-start: 3;
  grid-column-start: 4;
`
export const MenuSearchItem = styled(MenuStaffItem)`
  grid-column-start: 4;
  grid-column-start: 5;
`
export const MenuLogoutItem = styled(MenuStaffItem)`
  grid-column-start: 5;
  grid-column-start: 6;
`
export const MenuLocaleSwitchItem = styled(MenuStaffItem)`
  grid-column-start: 6;
  grid-column-start: 7;
`
