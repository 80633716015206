import styled from 'styled-components'
import { WIDE_SCREEN } from 'constants/breakpoints'

export const EmptyStaffTitle = styled.div`
  margin: 1rem 0;
  text-align: center;
`
export const EmptyStaffButton = styled.button`
  margin: 0 0 1rem 0;
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    margin: 1rem 0;
  }
`
export const EmptyStaffWrapper = styled.div`
  font-family: 'Supermolot', 'sans-serif';
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    font-size: 24px;
  }
`
