import styled from 'styled-components'

export const Container = styled.div`
form {
  display: flex;
  flex-direction: column;
  & > input {
    margin: 0.5rem 0;
  }
  & > textarea {
    margin: 0.5rem 0;
  }
}
`