import React from 'react'
import ProjectCardFieldForm from './components/ProjectCardFieldForm'
import { useTranslation } from 'react-i18next'
import CancelButton from '../CancelButton'
import { NewProjectContainer } from './styles'

function NewProjectCard(props) {
  const { closeModal } = props
  const { t } = useTranslation('NewProjectCard')
  return (
    <>
      <CancelButton closeModal={closeModal} />
      <NewProjectContainer>
        <div>{t('AddProject')}</div>
        <ProjectCardFieldForm closeModal={closeModal} />
      </NewProjectContainer>
    </>
  )
}

export default NewProjectCard
