import { gql } from '@apollo/client'

export const STAFF = gql`
  query staff($id: String!) {
    staff(id: $id) {
      staff {
        id
        firstName
        lastName
        photoURL
        description
        archive
        totalEmploymentPercentage
        createdAt
        updatedAt
        staffsConnection {
          id
          rateStaff
          employmentPercentage
          active
          projectId
        }
      }
    }
  }
`

export const STAFFS = gql`
  query {
    staffs {
      staffs {
        id
        firstName
        lastName
        photoURL
        description
        archive
        totalEmploymentPercentage
        createdAt
        updatedAt
        skillStaff {
          skill {
            name
          }
        }
      }
      errors {
        message
        statusCode
      }
    }
  }
`

export const ADD_STAFF = gql`
  mutation addStaff(
    $firstName: String!
    $lastName: String!
    $description: String
    $photo: Upload
  ) {
    addStaff(
      data: {
        firstName: $firstName
        lastName: $lastName
        description: $description
        photo: $photo
      }
    ) {
      staff {
        id
        firstName
        lastName
        photoURL
      }
      errors {
        message
        statusCode
      }
    }
  }
`

export const ARCH_STAFF = gql`
  mutation archStaff($id: String!, $archived: Boolean!) {
    archStaff(id: $id, archived: $archived) {
      staff {
        id
        firstName
        lastName
        photoURL
      }
      errors {
        message
        statusCode
      }
    }
  }
`

export const DEL_STAFF = gql`
  mutation delStaff($id: String!) {
    delStaff(id: $id) {
      staff {
        id
        firstName
        lastName
        rate
      }
      errors {
        message
        statusCode
      }
    }
  }
`

export const UPDATE_STAFF = gql`
  mutation updStaff(
    $id: String!
    $lastName: String!
    $firstName: String!
    $photo: Upload
    $description: String
  ) {
    updStaff(
      data: {
        lastName: $lastName
        firstName: $firstName
        description: $description
        photo: $photo
      }
      id: $id
    ) {
      staff {
        id
        firstName
        lastName
        photoURL
        description
      }
      errors {
        message
        statusCode
      }
    }
  }
`

export const STAFFS_IN_ARCHIVE = gql`
  query {
    staffsArchive {
      staffs {
        id
        firstName
        lastName
        description
        photoURL
      }
      errors {
        message
        statusCode
      }
    }
  }
`

export const AVAILABLE_STAFF = gql`
  query {
    availableStaff {
      staffs {
        id
        firstName
        lastName
        photoURL
        totalEmploymentPercentage
        nullRatePercentage
        skillStaff {
          skill {
            name
          }
        }
        englishLevel {
          englishLevel
        }
      }
      errors {
        message
        statusCode
      }
    }
  }
`

export const STAFF_UPDATED = gql`
  subscription {
    staffUpdated {
      staff {
        id
        firstName
        lastName
        photoURL
        description
      }
    }
  }
`

export const STAFF_ARCHIVED = gql`
  subscription {
    staffArchived {
      staff {
        id
        firstName
        lastName
        photoURL
        description
      }
    }
  }
`

export const STAFF_ADDED = gql`
  subscription {
    staffAdded {
      staff {
        id
        firstName
        lastName
        photoURL
        description
      }
    }
  }
`
