import React from 'react'
import { FieldLine, BottomBar } from './styles'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { ARCH_PROJECT, PROJECTS_IN_ARCHIVE } from 'api/project'
import { Button } from 'antd'
import { message } from 'antd'
import CancelButton from 'components/CancelButton'

function ProjectDetailsInArchive(props) {
  const { t } = useTranslation(['ProjectCardInArchive', 'ProjectMenuDetails'])
  const {
    projectInfo: { id, name, description },
    closeModal
  } = props

  const [archProject] = useMutation(ARCH_PROJECT)

  const backToBoard = () => {
    archProject({
      variables: {
        id,
        archived: false
      },
      refetchQueries: [{ query: PROJECTS_IN_ARCHIVE }]
    }).then(response => {
      response.data.archProject.project
        ? message.success(
            t('ProjectMenuDetails:RemoveProjectFromArchiveSuccess')
          )
        : message.error(t('ProjectMenuDetails:RemoveProjectFromArchiveError'))
      closeModal()
    })
  }

  return (
    <>
      <CancelButton closeModal={closeModal} />
      <FieldLine>
        {t('Name')} {name}
      </FieldLine>
      <FieldLine>
        {t('Description')} {description}
      </FieldLine>
      <BottomBar>
        <Button type="primary" onClick={backToBoard}>
          {t('BackToBoard')}
        </Button>
      </BottomBar>
    </>
  )
}

export default ProjectDetailsInArchive
