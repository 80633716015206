import { Form, Input } from 'antd'
import styled from 'styled-components'
import { MOBILE, WIDE_SCREEN } from 'constants/breakpoints'

export const AntdForm = styled(Form)`
  .ant-row.ant-form-item,
  .ant-form-item-control-input-content,
  .ant-upload-picture-card-wrapper {
    display: flex;
    justify-content: center;
  }
  @media only screen and (max-width: ${MOBILE}) {
    .ant-row.ant-form-item {
      margin: 0.625rem 0 0;
    }
    .ant-form-item-label {
      padding-bottom: 0.25rem;
    }
    #staffFormBtn {
      margin-top: 0.5rem;
    }
  }
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    .ant-row.ant-form-item {
      width: 400px;
    }
    .ant-form-item-label > label {
      font-size: 22px;
    }
    .ant-form-item-label
      > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      font-size: 22px;
    }
    .ant-input {
      font-size: 22px;
    }
    .ant-btn.ant-btn-primary {
      font-size: 22px;
      margin-top: 10px;
      height: 50px;
    }
    .ant-form-item-explain.ant-form-item-explain-error {
      font-size: 21px;
      margin-bottom: 15px;
    }
  }
`

export const Item = styled(Form.Item)`
  .ant-form-item-explain,
  .ant-form-item-extra,
  .ant-form-item-explain.ant-form-item-explain-error {
    max-width: 200px;
  }
`

export const TextArea = styled(Input.TextArea)`
  border-radius: 0;
`
