import { Button } from 'antd'
import styled from 'styled-components'
import { WIDE_SCREEN } from 'constants/breakpoints'
import BaseCancelButton from 'components/CancelButton'

export const BottomBar = styled.div`
  font-family: 'Supermolot', 'sans-serif';
  display: flex;
  flex-direction: column;
  float: left;
  width: 300px;
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    width: 500px;
  }
`
export const ButtonInProjectMenu = styled(Button)`
  width: 100%;
  margin: 3px;
  border-radius: 5px;
  text-align: left;
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    width: 95%;
    font-size: 24px;
    height: 50px;
    margin: 0.5rem;
  }
`

export const CancelButton = styled(BaseCancelButton)`
  margin: 0 0 1rem auto;
`
