import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { Tooltip, Avatar, message } from 'antd'
import {
  EditOutlined,
  DeleteOutlined,
  UserOutlined,
  PercentageOutlined
} from '@ant-design/icons'
import { useMediaQuery } from 'react-responsive'
import { WIDE_SCREEN } from 'constants/breakpoints'
import { STAFFS_ON_PROJECT, DEL_STAFF_FROM_PROJECT } from 'api/project'
import {
  EmployeeItem,
  RightSideButtons,
  AvatarContainer,
  StaffListContainer,
  NameField
} from './styles'
import EditProjectStaffDetailsModal from 'components/EditProjectStaffDetailsModal'
import Modal from 'components/Modal'
import EmptyStaffMenu from './components/EmptyStaffMenu'
import CancelButton from 'components/CancelButton'
import { AVAILABLE_STAFF } from 'api/staff'

function ProjectCardStaffDetails(props) {
  const { t } = useTranslation(['ProjectCardStaffDetails', 'Error'])
  const { projectId, closeStaffModal, toggleShowAddStaffModal } = props
  const [isEditProjectStaffDetails, setIsEditProjectStaffDetails] = useState(
    false
  )
  const [currentStaff, setCurrentStaff] = useState({})
  const { error, loading, data } = useQuery(STAFFS_ON_PROJECT, {
    variables: {
      projectId,
      rateSort: 'asc'
    },
    fetchPolicy: 'network-only'
  })
  const [delStaffByProject] = useMutation(DEL_STAFF_FROM_PROJECT)

  const findStaffIndex = (projectId, staffInfo) => {
    return staffInfo.staffsConnection.findIndex(
      data => data.projectId === projectId
    )
  }

  const isWideScreen = useMediaQuery({
    query: `(min-width: ${WIDE_SCREEN})`
  })

  const handleClick = id => {
    delStaffByProject({
      variables: {
        projectId: projectId,
        staffId: id
      },
      refetchQueries: [
        {
          query: STAFFS_ON_PROJECT,
          variables: {
            rateSort: 'asc',
            projectId: projectId
          }
        },
        {
          query: AVAILABLE_STAFF
        }
      ]
    }).then(() => {
      message.success(t('DeleteStaffSuccess'))
    })
  }

  if (loading) return <p>{t('Error:Loading')}</p>
  if (error) return <p>{t('Error:Error')}</p>

  const staffs = data?.staffsOnProject.staffs

  const updateCurrentProjectStaffDetails = (id, detailsType, detailsData) => {
    setCurrentStaff({ id, detailsType, detailsData })
    toggleEditProjectStaffDetailsModal()
  }

  const toggleEditProjectStaffDetailsModal = () => {
    setIsEditProjectStaffDetails(!isEditProjectStaffDetails)
  }

  return (
    <>
      <CancelButton closeModal={closeStaffModal} />
      <StaffListContainer>
        {staffs.length ? (
          staffs.map(
            (
              { id, firstName, lastName, staffsConnection, photoURL },
              index
            ) => (
              <EmployeeItem
                key={id}
                id={id}
                firstName={firstName}
                lastName={lastName}
                rate={
                  staffsConnection[findStaffIndex(projectId, staffs[index])]
                    .rateStaff
                }
                photoURL={photoURL}
              >
                <AvatarContainer>
                  <Avatar
                    id="avatar"
                    size={isWideScreen ? 100 : 'large'}
                    icon={
                      photoURL === '' ? (
                        <UserOutlined />
                      ) : (
                        <img src={photoURL} alt="icon" />
                      )
                    }
                  />
                </AvatarContainer>
                <NameField>
                  {firstName} {lastName}
                </NameField>
                <RightSideButtons>
                  <Tooltip
                    placement="topLeft"
                    title={t('EditEmploymentPercentage')}
                  >
                    <PercentageOutlined
                      className="icon"
                      onClick={() =>
                        updateCurrentProjectStaffDetails(
                          id,
                          'employmentPercentage',
                          staffsConnection[
                            findStaffIndex(projectId, staffs[index])
                          ]
                        )
                      }
                    />
                  </Tooltip>
                  <Tooltip placement="topLeft" title={t('EditRate')}>
                    <EditOutlined
                      className="icon"
                      onClick={() =>
                        updateCurrentProjectStaffDetails(id, 'rateStaff', {
                          rateStaff:
                            staffsConnection[
                              findStaffIndex(projectId, staffs[index])
                            ].rateStaff
                        })
                      }
                    />
                  </Tooltip>
                  <Tooltip placement="topLeft" title={t('RemoveTitle')}>
                    <DeleteOutlined
                      className="icon"
                      onClick={() => handleClick(id)}
                    />
                  </Tooltip>
                </RightSideButtons>
              </EmployeeItem>
            )
          )
        ) : (
          <EmptyStaffMenu toggleShowAddStaffModal={toggleShowAddStaffModal} />
        )}
      </StaffListContainer>

      <Modal
        showModal={isEditProjectStaffDetails}
        closeModal={toggleEditProjectStaffDetailsModal}
      >
        <EditProjectStaffDetailsModal
          closeModal={toggleEditProjectStaffDetailsModal}
          projectId={projectId}
          currentStaff={currentStaff}
        />
      </Modal>
    </>
  )
}

export default ProjectCardStaffDetails
