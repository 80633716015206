export const BREAKPOINT_COLUMNS_OBJ = {
  default: 5,
  1400: 5,
  1200: 4,
  1100: 3,
  800: 2,
  550: 1
}

export const BOARD_COLUMN_COUNT = 3
