import { createContext } from 'react'

const defaultContext = {
  dndState: [],
  setDndState: () => {
    throw 'Context is used out of provider'
  }
}

export const DndStateContext = createContext(defaultContext)
