import { useTranslation } from 'react-i18next'
import { validateRateField } from 'services/validation'

function useRateValidationRules() {
  const { t } = useTranslation('ValidationMessage')
  return [
    {
      required: true,
      message: t('RateValidationMessage')
    },
    (() => ({
      validator(_, value) {
        return validateRateField(value, t('InvalidRate'))
      }
    }))()
  ]
}
export default useRateValidationRules
