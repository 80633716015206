import styled from 'styled-components'
import { WIDE_SCREEN } from 'constants/breakpoints'

export const FieldLine = styled.div`
  text-align: left;
  overflow: auto;
  max-height: 10rem;
  margin: 1rem 0;
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    font-size: 22px;
    margin: 1.25rem 0;
  }
`

export const ProjectName = styled.p`
  max-width: 260px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Line = styled.div`
  display: flex;
`

export const RightSideButtons = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: 4px;
  .icon {
    font-size: 15px;
    margin: 4px;
  }
  .icon:hover {
    color: red;
  }
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    #edit {
      font-size: 28px;
      margin: 0.5rem;
    }
    #delete {
      font-size: 28px;
      margin: 0.5rem;
    }
  }
`
