import { gql } from '@apollo/client'

export const ENGLISH_LEVEL_BY_STAFF = gql`
  query getEnglishLevel($staffId: String!) {
    getEnglishLevel(staffId: $staffId) {
      englishLevelStaff {
        englishLevel
      }
    }
  }
`

export const UPDATE_ENGLISH_LEVEL_BY_STAFF = gql`
  mutation updateEnglishLevelStaff(
    $staffId: String!
    $englishLevel: EnglishLevelEnum!
  ) {
    updateEnglishLevelStaff(staffId: $staffId, englishLevel: $englishLevel) {
      status
      errors {
        statusCode
        message
      }
    }
  }
`
