import React, { useState, useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { AVAILABLE_STAFF, STAFF } from 'api/staff'
import useArchiveStaffMutation from 'hooks/useArchiveStaffMutation'
import {
  DEL_ARCHIVE_STAFF_FROM_PROJECTS,
  PROJECTS_BY_STAFF,
  PROJECTS_IN_PORTFOLIO,
  DELETE_PROJECT_PORTFOLIO,
  RENAMING_PROJECT_PORTFOLIO,
  GET_PROJECTS,
  ADD_PROJECT_PORTFOLIO,
  PROJECTS_IN_ARCHIVE
} from 'api/project'
import { EmployeeCardWrapper, ButtonsContainer, BaseButton } from './styles'
import { useTranslation } from 'react-i18next'
import EditEmployeeCard from '../EditEmployeeCard'
import CancelButton from '../CancelButton'
import EmployeeDetails from '../EmployeeDetails'
import { Button, message } from 'antd'
import Modal from 'components/Modal'
import WarningModal from 'components/WarningModal'
import EmployeePortfolio from 'components/EmployeePortfolio'
import SkillCard from 'components/SkillCard'
import { SKILLS_BY_STAFF } from 'api/skill'
import { EnglishLevelCard } from 'components/EnglishLevelCard'
import { ENGLISH_LEVEL_BY_STAFF } from 'api/englishLevel'
import ProjectPortfolioCard from './components/ProjectPortfolioCard'

function EmployeeCard({ closeModal, currentStaffId }) {
  const { t } = useTranslation([
    'EmployeeCard',
    'Error',
    'ArchiveContainer',
    'ProjectCardStaffDetails'
  ])
  const [addPortfolioProject] = useMutation(ADD_PROJECT_PORTFOLIO)
  const [deleteProjectPortfolio] = useMutation(DELETE_PROJECT_PORTFOLIO)
  const [renamingPortfolioProject] = useMutation(RENAMING_PROJECT_PORTFOLIO)
  const { loading, error, data } = useQuery(STAFF, {
    variables: { id: currentStaffId }
  })
  const [isWarningModalShow, setIsWarningModalShow] = useState(false)
  const [projectsByStaff, setProjectsByStaff] = useState([])
  const [projectsInPortfolio, setProjectsInPortfolio] = useState([])
  const [staffSkills, setStaffSkills] = useState([])
  const [isPortfolio, setIsPortfolio] = useState(false)
  const [isSkills, setIsSkills] = useState(false)
  const [isEnglishLevel, setIsEnglishLevel] = useState(false)
  const [englishLevel, setEnglishLevel] = useState(null)
  const [initialValuesProjectCard, setInitialValuesProjectCard] = useState(null)

  const archStaff = useArchiveStaffMutation(true, currentStaffId)
  const [delArchiveStaffFromProjects] = useMutation(
    DEL_ARCHIVE_STAFF_FROM_PROJECTS
  )

  const [isEdit, setIsEdit] = useState(false)
  const [isAddProject, setIsAddProject] = useState(false)
  const addStaffInArchive = () => {
    archStaff().then(response => {
      closeModal()
      !response
        ? message.error(t('ArchiveContainer:ArchivedStaffError'))
        : message.success(t('ArchiveContainer:ArchivedStaffSuccess'))
      delArchiveStaffFromProjects({
        variables: {
          staffId: currentStaffId
        }
      })
    })
  }

  const { refetch } = useQuery(PROJECTS_BY_STAFF, {
    variables: { id: currentStaffId },
    fetchPolicy: 'network-only',
    onCompleted: data => setProjectsByStaff([...data.projectsByStaff.projects])
  })
  useQuery(PROJECTS_IN_PORTFOLIO, {
    variables: { id: currentStaffId },
    fetchPolicy: 'network-only',
    onCompleted: data =>
      setProjectsInPortfolio([...data.projectsByStaffInPortfolio.projects])
  })
  const { refetch: skillStaffRefetch } = useQuery(SKILLS_BY_STAFF, {
    variables: { staffId: currentStaffId },
    fetchPolicy: 'network-only',
    onCompleted: data => setStaffSkills(data.getSkillsByStaff.skillStaff),
    notifyOnNetworkStatusChange: true
  })

  const { refetch: englishLevelRefetch } = useQuery(ENGLISH_LEVEL_BY_STAFF, {
    variables: { staffId: currentStaffId },
    fetchPolicy: 'network-only',
    onCompleted: data =>
      setEnglishLevel(data?.getEnglishLevel?.englishLevelStaff?.englishLevel),
    notifyOnNetworkStatusChange: true
  })

  useEffect(() => {
    refetch()
  }, [refetch])

  const setIsEditModeActive = () => {
    setIsEdit(!isEdit)
  }
  const setIsPortfolioActive = () => {
    setIsPortfolio(!isPortfolio)
  }
  const setIsSkillsActive = () => {
    setIsSkills(!isSkills)
  }
  const toggleIsWarningModalShow = () => {
    setIsWarningModalShow(!isWarningModalShow)
  }
  const setIsEnglishLevelActive = () => {
    setIsEnglishLevel(!isEnglishLevel)
  }
  const handleRenamePortfolioProject = ({ projectId }) => {
    const projectName = projectsInPortfolio.find(pip => pip.id === projectId)
      .name
    setInitialValuesProjectCard({ projectId, projectName })
    setIsAddProject(!isAddProject)
  }

  const handleProjectForPortfolio = async ({ projectName }) => {
    if (!initialValuesProjectCard?.projectId) {
      const {
        data: {
          addPortfolioProject: { project }
        }
      } = await addPortfolioProject({
        variables: { name: projectName, staffId: currentStaffId },
        refetchQueries: {
          query: PROJECTS_IN_ARCHIVE,
          fetchPolicy: 'network-only'
        }
      })

      setProjectsInPortfolio([...projectsInPortfolio, project])
      return setIsAddProject(!isAddProject)
    }
    const {
      data: {
        renamingPortfolioProject: { project }
      }
    } = await renamingPortfolioProject({
      variables: {
        projectId: initialValuesProjectCard.projectId,
        name: projectName
      },
      refetchQueries: [
        { query: GET_PROJECTS, fetchPolicy: 'network-only' },
        { query: AVAILABLE_STAFF },
        { query: PROJECTS_IN_ARCHIVE }
      ]
    })
    setProjectsInPortfolio([
      ...projectsInPortfolio.filter(
        pip => pip.id !== initialValuesProjectCard.projectId
      ),
      project
    ])
    setInitialValuesProjectCard(null)
    message.success(t('ProjectCardStaffDetails:ProjectNameUpdated'))
    setIsAddProject(!isAddProject)
  }

  const handleDeleteProjectFromPortfolio = async ({ projectId }) => {
    const {
      data: {
        deleteProjectPortfolio: { errors }
      }
    } = await deleteProjectPortfolio({
      variables: {
        projectId,
        staffId: currentStaffId
      },
      refetchQueries: [{ query: AVAILABLE_STAFF }]
    })
    if (!errors.length) {
      setProjectsInPortfolio([
        ...projectsInPortfolio.filter(pip => pip.id !== projectId)
      ])
      message.success(t('ProjectCardStaffDetails:ProjectDeletedFromPortfolio'))
    }
  }

  if (loading) return <p>{t('Error:Loading')}</p>
  if (error) return <p>{t('Error:Error')}</p>
  const staffData = data.staff.staff

  if (isEdit)
    return (
      <EditEmployeeCard
        staff={staffData}
        setIsEditModeActive={setIsEditModeActive}
      />
    )
  if (isAddProject)
    return (
      <ProjectPortfolioCard
        initailValues={initialValuesProjectCard}
        handleNewProjectForPortfolio={handleProjectForPortfolio}
        closeModal={setIsAddProject}
      />
    )
  if (isSkills)
    return (
      <SkillCard
        staffId={currentStaffId}
        closeModal={setIsSkillsActive}
        skillStaffRefetch={skillStaffRefetch}
      />
    )

  if (isEnglishLevel)
    return (
      <EnglishLevelCard
        staffId={currentStaffId}
        closeModal={setIsEnglishLevelActive}
        englishLevelRefetch={englishLevelRefetch}
        englishLevel={englishLevel}
      />
    )

  return (
    <>
      <CancelButton closeModal={closeModal} />
      <EmployeeCardWrapper>
        <EmployeeDetails
          staffData={staffData}
          projectsByStaff={projectsByStaff}
          staffSkills={staffSkills}
          englishLevel={englishLevel}
        />
        <ButtonsContainer>
          <Button type="primary" onClick={toggleIsWarningModalShow}>
            {t('Archive')}
          </Button>
          <BaseButton onClick={setIsEditModeActive}>{t('Edit')}</BaseButton>
          <BaseButton onClick={setIsSkillsActive}>{t('Skills')}</BaseButton>
          <BaseButton onClick={setIsEnglishLevelActive}>
            {t('EnglishLevel')}
          </BaseButton>
          <BaseButton onClick={setIsPortfolioActive}>
            {t('Portfolio')}
          </BaseButton>
        </ButtonsContainer>
        {isPortfolio && (
          <EmployeePortfolio
            onAddPortfolioClick={() => setIsAddProject(!isAddProject)}
            projects={projectsInPortfolio}
            onRenameProjectFromPortfolio={handleRenamePortfolioProject}
            onDeleteProjectFromPortfolio={handleDeleteProjectFromPortfolio}
          />
        )}
      </EmployeeCardWrapper>
      <Modal
        showModal={isWarningModalShow}
        closeModal={toggleIsWarningModalShow}
      >
        <WarningModal
          closeModal={toggleIsWarningModalShow}
          confirmHandle={addStaffInArchive}
          messageKey={'ConfirmArchiveStaff'}
        />
      </Modal>
    </>
  )
}

export default EmployeeCard
