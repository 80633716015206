import React from 'react'
import { useTranslation } from 'react-i18next'
import StaffContainer from '../StaffContainer'
import { Tooltip, Col } from 'antd'
import 'antd/dist/antd.css'
import {
  Card,
  Header,
  Info,
  ProjectName,
  ProjectAvatar,
  ProjectRate,
  Filter
} from './styles'
import VirtualProjectStaffContainer from '../VirtualProjectStaffContainer'
import emptyProject from 'images/project.svg'

function ProjectCardContent(props) {
  const {
    id,
    name,
    rate,
    photoURL,
    description,
    projectConnection: staff,
    filteredStaffs,
    projectIndex,
    handleClick,
    filterFieldValue,
    setFilterFieldValue,
    setFilter,
    isVirtual,
    onDrop
  } = props
  const { t } = useTranslation(['VirtualProjects', 'Filter'])

  return (
    <Card>
      <Header onClick={handleClick}>
        {isVirtual ? null : (
          <ProjectAvatar photoUrl={photoURL || emptyProject} />
        )}
        <Info>
          <Tooltip placement="top" title={description}>
            <ProjectName>{t(name)}</ProjectName>
          </Tooltip>
          <Col id="col">
            {isVirtual ? (
              <Filter
                allowClear
                onSearch={value => {
                  setFilterFieldValue(value)
                  setFilter(value)
                }}
                onChange={event => {
                  const value = event.target.value
                  setFilterFieldValue(value)
                  if (value === '') {
                    setFilter(value)
                  }
                }}
                placeholder={t('Filter:EnterName')}
                value={filterFieldValue}
              />
            ) : (
              <ProjectRate value={rate} prefix={'$'} />
            )}
          </Col>
        </Info>
      </Header>
      {isVirtual ? (
        <VirtualProjectStaffContainer
          projectName={name}
          staffs={filteredStaffs}
          onDrop={onDrop}
        />
      ) : (
        <StaffContainer
          projectId={id}
          dndState={staff}
          projectIndex={projectIndex}
          onDrop={onDrop}
        />
      )}
    </Card>
  )
}
export default ProjectCardContent
