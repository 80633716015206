import React from 'react'
import EmployeeCardFieldForm from './components/EmployeeCardFieldForm'

function NewEmployeeCard({ closeModal }) {
  return (
    <div className="Card">
      <EmployeeCardFieldForm closeModal={closeModal} />
    </div>
  )
}

export default NewEmployeeCard
