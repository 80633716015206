import React from 'react'
import { useTranslation } from 'react-i18next'
import CancelButton from '../CancelButton'
import EditEmployeeCardFieldForm from './components/EditEmployeeFieldForm'
import { EditEmployeeCardTitle } from './styles'

function EditEmployeeCard(props) {
  const { staff, setIsEditModeActive } = props
  const { t } = useTranslation('NewEmployeeCard')

  return (
    <>
      <CancelButton closeModal={setIsEditModeActive} />
      <EditEmployeeCardTitle>{t('EditEmployee')}</EditEmployeeCardTitle>
      <EditEmployeeCardFieldForm
        initialValues={staff}
        setIsEditModeActive={setIsEditModeActive}
      />
    </>
  )
}

export default EditEmployeeCard
