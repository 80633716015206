import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { STAFFS_IN_ARCHIVE } from 'api/staff'
import useStaffSort from 'hooks/useStaffSort'
import { staffCompareFunction } from 'services/sortCompareFunctions'
import { EmployeeItem, ArchiveStaffsContainer, SortButton } from './styles'
import Modal from 'components/Modal'
import StaffDetailsInArchive from './StaffDetailsInArchive'

function ArchiveStaffs() {
  const { t } = useTranslation('Error')
  const [sortField, sortClick, staffListWithSort, sortLabel] = useStaffSort()
  const { loading, error, data } = useQuery(STAFFS_IN_ARCHIVE)

  const [staffDetailsIsOpen, setStaffDetailsIsOpen] = useState(false)
  const [currentEmployeeId, setCurrentEmployeeId] = useState(null)

  const toggleStaffDetailsInArchive = id => {
    setStaffDetailsIsOpen(!staffDetailsIsOpen)
    setCurrentEmployeeId(id)
  }

  if (loading) return <p>{t('Loading')}</p>
  if (error) return <p>{t('Error')}</p>

  const sortData = data.staffsArchive.staffs
    .slice()
    .sort((a, b) => staffCompareFunction(a, b, sortField))

  if (sortData.length === 0) return <div>{t('ArchiveIsEmpty')}</div>

  return (
    <ArchiveStaffsContainer>
      <SortButton onClick={sortClick}>{sortLabel}</SortButton>
      {sortData.map(({ id, firstName, lastName }) => (
        <EmployeeItem key={id} onClick={() => toggleStaffDetailsInArchive(id)}>
          {staffListWithSort(lastName, firstName)}
        </EmployeeItem>
      ))}
      <Modal
        showModal={staffDetailsIsOpen}
        closeModal={toggleStaffDetailsInArchive}
      >
        <StaffDetailsInArchive
          closeModal={toggleStaffDetailsInArchive}
          currentEmployeeId={currentEmployeeId}
        />
      </Modal>
    </ArchiveStaffsContainer>
  )
}
export default ArchiveStaffs
