import React from 'react'
import { Select, Button, Form } from 'antd'
import { useMutation } from '@apollo/client'
import { UPDATE_ENGLISH_LEVEL_BY_STAFF } from 'api/englishLevel'
import CancelButton from 'components/CancelButton'
import { useTranslation } from 'react-i18next'
import { englishLevels } from 'constants/englishLevelConst'

export const EnglishLevelCard = ({
  staffId,
  closeModal,
  englishLevelRefetch,
  englishLevel
}) => {
  const [updateEnglishLevelByStaff, { loading }] = useMutation(
    UPDATE_ENGLISH_LEVEL_BY_STAFF,
    {
      onCompleted: () => {
        englishLevelRefetch()
        closeModal()
      }
    }
  )

  const handleSubmitForm = ({ englishLevel }) => {
    updateEnglishLevelByStaff({
      variables: { staffId, englishLevel }
    })
  }
  const { Option } = Select
  const { t } = useTranslation('EnglishLevel')
  const initialValue = englishLevel
    ? englishLevels.find(({ name }) => name === englishLevel).value
    : englishLevels[0].value

  return (
    <>
      <CancelButton closeModal={closeModal} />
      <Form
        name="updateEnglisLevelByStaff"
        layout="vertical"
        onFinish={handleSubmitForm}
        autoComplete="off"
      >
        <Form.Item
          name="englishLevel"
          rules={[{ required: true, message: t('selectRequire') }]}
          initialValue={initialValue}
          label={t('englishLevel')}
        >
          <Select>
            {englishLevels.map(({ name, value }, index) => (
              <Option key={index} value={value}>
                {name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            {t('submit')}
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}
