import styled from 'styled-components'
import { MOBILE, WIDE_SCREEN } from 'constants/breakpoints'

export const Container = styled.div`
  margin: 1rem;
  overflow: auto;
  overflow-x: hidden;
  @media only screen and (max-width: ${MOBILE}) {
    width: 300px;
    font-size: 0.9rem;
    margin: 0 0 0.5rem;
  }
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    font-size: 22px;
    .react-tabs__tab-list {
      margin: 0 10px 10px;
    }
  }
`
