import React, { useState } from 'react'
import { message } from 'antd'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import 'antd/dist/antd.css'
import { UploadPhoto, Image, Div } from './style'
import { AVAILABLE_TYPES } from 'constants/avatarConst'
import { useTranslation } from 'react-i18next'

const Avatar = ({ getPhotoFile, photoURL }) => {
  const { t } = useTranslation(['ValidationMessage', 'UI'])

  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState('')

  const getBase64 = (img, callback) => {
    const reader = new FileReader()
    if (!img) return
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }
  const beforeUpload = file => {
    const validate = AVAILABLE_TYPES.every(type => type !== file.type)
    if (validate) {
      message.error(t('PhotoFormat'))
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error(t('PhotoSize'))
    }
    return !validate && isLt2M
  }
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok')
    }, 0)
  }

  const uploadButton = (
    <Div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div>{t('Upload', { ns: 'UI' })}</div>
    </Div>
  )
  return (
    <UploadPhoto
      accept=".jpg,.png"
      listType="picture-card"
      customRequest={dummyRequest}
      className="avatar-uploader"
      showUploadList={false}
      beforeUpload={beforeUpload}
      onChange={info => {
        if (info.file.status === 'uploading') {
          getBase64(info.file.originFileObj, imgUrl => {
            getPhotoFile(info.file.originFileObj)
            setLoading(false)
            setImageUrl(imgUrl)
          })
        }
      }}
    >
      {imageUrl || photoURL ? (
        <Image imageUrl={imageUrl} photoURL={photoURL}></Image>
      ) : (
        uploadButton
      )}
    </UploadPhoto>
  )
}

export default Avatar
