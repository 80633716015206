import styled from 'styled-components'
import { Input } from 'antd'
import { LAPTOP_OR_DESKTOP, WIDE_SCREEN } from 'constants/breakpoints'

export const StyledInput = styled(Input.Search)`
  width: 180px;
  vertical-align: middle;
  @media only screen and (min-width: ${LAPTOP_OR_DESKTOP}) {
    input {
      font-size: 15px;
    }
  }
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    width: 230px;
    input {
      font-size: 22px;
    }
    svg {
      width: 20px;
      height: 20px;
    }
  }
`
