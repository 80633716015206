import { RATE_MIN_VALUE, RATE_MAX_VALUE } from 'constants/rateFormConst'

export const validateRateField = async (value, message) => {
  const regex = /^(?=.*\d)\d*(?:\.\d{0,2})?$/
  if (!regex.test(value) || value < RATE_MIN_VALUE || value > RATE_MAX_VALUE)
    return Promise.reject(new Error(message))
  return Promise.resolve()
}

export const checkIsButtonDisabled = (form, fieldsToTouchValidate) =>
  (fieldsToTouchValidate &&
    !form.isFieldsTouched(fieldsToTouchValidate, true)) ||
  form.getFieldsError().filter(({ errors }) => errors.length).length

export const validateOnEmptyTextField = async ({value, message}) => {
  if (value.replace(/<\/?[^>]+(>|$)/g, "").trim() === '') {
    return Promise.reject(message);
  };
  return Promise.resolve();
}
