import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { PROJECT, UPDATE_PROJECT } from 'api/project'
import { message } from 'antd'
import { ProjectForm } from 'components/UI/ProjectForm'
import Modal from 'components/Modal'
import WarningModal from 'components/WarningModal'

function EditProjectFieldForm(props) {
  const { initialValues, editProject } = props
  const [updProject] = useMutation(UPDATE_PROJECT)
  const { t } = useTranslation('ProjectCard')
  const [state, setState] = useState({ photo: '' })
  const [isWarningModalShow, setIsWarningModalShow] = useState(false)
  const [formData, setFormData] = useState({})

  const getPhotoFile = photo => {
    setState({
      photo: photo
    })
  }

  const toggleIsWarningModalShow = values => {
    setFormData(values)
    setIsWarningModalShow(!isWarningModalShow)
  }

  const onSubmit = () => {
    updProject({
      variables: {
        id: initialValues.id,
        name: formData.name.trim(),
        photo: state.photo,
        description: formData.description?.trim()
      },
      refetchQueries: [
        {
          query: PROJECT,
          variables: {
            id: initialValues.id
          }
        }
      ]
    }).then(response => {
      const data = response.data.updProject

      if (data.project) message.success(t('EditProjectSuccess'))
      else {
        const errorCode = data.errors[0].statusCode

        if (errorCode === '409') return message.error(t('ProjectAlreadyExists'))
        message.error(t('ProjectError'))
      }

      editProject()
    })
  }

  return (
    <>
      <Form
        initialValues={initialValues}
        onSubmit={toggleIsWarningModalShow}
        render={({ handleSubmit }) => (
          <ProjectForm
            projectData={initialValues}
            getPhotoFile={getPhotoFile}
            handleSubmit={handleSubmit}
          />
        )}
      />
      <Modal
        showModal={isWarningModalShow}
        closeModal={toggleIsWarningModalShow}
      >
        <WarningModal
          closeModal={toggleIsWarningModalShow}
          confirmHandle={onSubmit}
          messageKey={'ConfirmUpdateProjectInfo'}
        />
      </Modal>
    </>
  )
}

export default EditProjectFieldForm
