import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import Modal from 'components/Modal'
import { STAFFS } from 'api/staff'
import EmployeeCard from 'components/EmployeeCard'
import useStaffSort from 'hooks/useStaffSort'
import { staffCompareFunction } from 'services/sortCompareFunctions'
import { EmployeeItem } from './styles'
import { Container, SortButton } from './styles'

function StaffList() {
  const { t } = useTranslation('Error')
  const [sortField, sortClick, staffListWithSort, sortLabel] = useStaffSort()
  const { loading, error, data } = useQuery(STAFFS)

  const [isOpen, setIsOpen] = useState(false)
  const [currentStaffId, setCurrentStaffId] = useState(null)

  const handleClick = id => {
    setIsOpen(!isOpen)
    setCurrentStaffId(id)
  }

  if (loading) return <p>{t('Loading')}</p>
  if (error) return <p>{t('Error')}</p>

  const sortData = data.staffs.staffs
    .slice()
    .sort((a, b) => staffCompareFunction(a, b, sortField))

  return (
    <Container>
      <SortButton onClick={sortClick}>{sortLabel}</SortButton>
      {sortData.map(({ id, firstName, lastName }) => (
        <EmployeeItem onClick={() => handleClick(id)} key={id}>
          {staffListWithSort(lastName, firstName)}
        </EmployeeItem>
      ))}
      <Modal showModal={isOpen} closeModal={handleClick}>
        <EmployeeCard
          currentStaffId={currentStaffId}
          closeModal={handleClick}
        />
      </Modal>
    </Container>
  )
}

export default StaffList
