import { Form } from 'antd'
import styled from 'styled-components'
import { WIDE_SCREEN } from 'constants/breakpoints'

export const SignInContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75vh;
`

export const AntdForm = styled(Form)`
  width: 400px;
  padding: 32px;
  border-radius: 10px;
  box-shadow: 0 4px 16px #ccc;
  letter-spacing: 1px;

  #submitButton {
    width: 100%;
  }

  @media only screen and (min-width: ${WIDE_SCREEN}) {
    width: 600px;
    padding: 50px;
    .ant-form-item-label > label {
      font-size: 26px;
    }
    .ant-form-item-label
      > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      font-size: 26px;
    }
    .ant-input.ant-input-lg {
      font-size: 26px;
    }
    .ant-btn.ant-btn-primary {
      font-size: 26px;
      margin-top: 10px;
      height: 60px;
    }
    .ant-form-item-explain.ant-form-item-explain-error {
      font-size: 24px;
      margin-bottom: 15px;
    }
    svg {
      width: 30px;
      height: 30px;
    }
  }
`

export const Title = styled.div`
  margin-top: 0;
  margin-bottom: 0.5rem;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 2em;
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    font-size: 3em;
    margin-bottom: 0.7rem;
  }
`
