import styled from 'styled-components'
import { grey } from 'theme/palette'
import { FULL_HD } from 'constants/breakpoints'

export const Employee = styled.div`
  padding: 0.3rem;
  border: 1px solid ${grey};
  margin: 0.3rem;
`

export const ModalTitle = styled.div`
  font-family: 'Supermolot', 'sans-serif';
  margin: 1rem 0;
`
export const ModalButtonContainer = styled.div`
  font-family: 'Supermolot', 'sans-serif';
  display: flex;
  justify-content: center;
  margin: 1rem 0;
`
export const ModalWrapper = styled.div`
  margin: 1rem;
`

export const DropContainer = styled.div`
  height: 100%;
  min-height: 6rem;
`

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`

export const RateSquare = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  margin: 3%;
  width: max-content;
  height: 23px;
  line-height: 23px;
  font-size: 15px;
  background: ${({ rate }) => (rate ? 'rgb(19, 55, 67)' : '#d67a7e')};
  color: white;
  border-radius: 10%;
  text-align: center;
  vertical-align: middle;
  opacity: 0.5;
  min-width: 23px;
  padding: 0 2px;
  @media only screen and (min-width: ${FULL_HD}) {
    min-width: 23px;
    width: max-content;
    padding: 0 2px;
    height: 1.15vw;
    font-size: 0.85vw;
    line-height: 1.15vw;
  }
`

export const WorkTimeImg = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  margin: 5%;
  background: url(${({ source }) => source}) no-repeat;
  background-position: center;
  background-size: cover;
  width: 23px;
  height: 23px;
  @media only screen and (min-width: ${FULL_HD}) {
    width: 1.2vw;
    height: 1.2vw;
  }
`
export const CircularBar = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  margin: 5%;
  @media only screen and (min-width: ${FULL_HD}) {
    width: 1.2vw;
    height: 1.2vw;
  }
  .ant-progress-circle .ant-progress-inner {
    background-color: #fff;
  }
`
