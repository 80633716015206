import { PERCENT_STEP } from 'constants/employmentPercentageConst'

export function getEmploymentPercentages(totalEmploymentPercentage) {
  if (totalEmploymentPercentage) {
    const employmentPercentageNum = totalEmploymentPercentage / PERCENT_STEP

    return Array.from(
      new Array(Math.round(employmentPercentageNum)).keys()
    )?.map(value => (value + 1) * PERCENT_STEP)
  }
}
