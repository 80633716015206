import styled from 'styled-components'
import { WIDE_SCREEN } from 'constants/breakpoints'

export const CancelWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  margin-right: 0em;
`
export const CancelIcon = styled.img`
  cursor: pointer;
  width: 30px;
  height: 30px;
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    width: 50px;
    height: 50px;
  }
`
