import styled from 'styled-components'
import { MOBILE, WIDE_SCREEN } from 'constants/breakpoints'

export const StaffCard = styled.div`
  display: flex;
  margin: 0.2rem;
  flex-direction: column;
  height: 160px;
  width: 100px;
  #avatar {
    width: 100px;
    height: 120px;
    line-height: 120px;
  }
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    height: 240px;
    width: 150px;
    margin: 0.5rem;
    #avatar {
      width: 150px;
      height: 180px;
      line-height: 215px;
    }
    svg {
      width: 50px;
      height: 50px;
    }
  }
`
export const AvailableStaffContainer = styled.div`
  max-height: 550px;
  display: flex;
  flex-flow: row wrap;
  overflow: auto;
  @media only screen and (max-width: ${MOBILE}) {
    height: 450px;
    width: 280px;
    justify-content: center;
  }
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    max-height: 900px;
    font-size: 22px;
  }
`
export const EmptyAvailableStaffLabel = styled.div`
  margin: 1rem;
  font-size: 16px;
`

export const FigureCaption = styled.span`
  width: 100px;
  text-align: center;
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    width: 150px;
  }
`
