import React from 'react'
import { BottomBar } from './styles'
import useArchiveStaffMutation from 'hooks/useArchiveStaffMutation'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { STAFF } from 'api/staff'
import EmployeeDetails from 'components/EmployeeDetails'
import { message } from 'antd'
import CancelButton from 'components/CancelButton'
import { ENGLISH_LEVEL_BY_STAFF } from 'api/englishLevel'
import { SKILLS_BY_STAFF } from 'api/skill'
import { PROJECTS_IN_PORTFOLIO } from 'api/project'

function StaffDetailsInArchive({ closeModal, currentEmployeeId }) {
  const { t } = useTranslation([
    'StaffCardInArchive',
    'ArchiveContainer',
    'Error'
  ])
  const { loading, error, data } = useQuery(STAFF, {
    variables: { id: currentEmployeeId }
  })
  const archStaff = useArchiveStaffMutation(false, currentEmployeeId)

  const removeFromArchive = () => {
    archStaff().then(response => {
      response.data.archStaff.staff
        ? message.success(t('ArchiveContainer:RemoveStaffFromArchiveSuccess'))
        : message.error(t('ArchiveContainer:RemoveStaffFromArchiveError'))
      closeModal()
    })
  }

  const { data: skillsData } = useQuery(SKILLS_BY_STAFF, {
    variables: { staffId: currentEmployeeId }
  })
  const { data: englishLevelData } = useQuery(ENGLISH_LEVEL_BY_STAFF, {
    variables: { staffId: currentEmployeeId }
  })
  const { data: portfolioData } = useQuery(PROJECTS_IN_PORTFOLIO, {
    variables: { id: currentEmployeeId }
  })

  if (loading) return <p>{t('Error:Loading')}</p>
  if (error) return <p>{t('Error:Error')}</p>

  const skills = skillsData?.getSkillsByStaff?.skillStaff || []
  const englishLevel =
    englishLevelData?.getEnglishLevel?.englishLevelStaff?.englishLevel || ''
  const projectsByStaff =
    portfolioData?.projectsByStaffInPortfolio?.projects || []
  const staffData = data.staff.staff

  return (
    <>
      <CancelButton closeModal={closeModal} />
      <EmployeeDetails
        staffData={staffData}
        staffSkills={skills}
        englishLevel={englishLevel}
        projectsByStaff={projectsByStaff}
      />
      <BottomBar>
        <button onClick={removeFromArchive}>{t('RemoveFromArchive')}</button>
      </BottomBar>
    </>
  )
}

export default StaffDetailsInArchive
