import React, { useContext } from 'react'
import { useSubscription } from '@apollo/client'
import {
  ARCHIVE_STAFF_BY_PROJECT_DELETED,
  PROJECT_ADDED,
  PROJECT_ARCHIVED,
  PROJECT_STAFF_UPDATED,
  PROJECT_UPDATED,
  RENAMED_PROJECT_PORTFOLIO,
  STAFF_ADDED_BY_PROJECT,
  STAFF_DELETED_BY_PROJECT,
  STAFF_REPLACED
} from 'api/project'
import { DndStateContext } from 'constants/dndStateContext'
import { projectIndex, insertStaffInProject } from 'services/dndState'
import { updateProject } from 'services/updateProject'
import { findInProjectStaffData } from 'services/findInProjectStaffData'
import { STAFF_UPDATED } from 'api/staff'
import { BOARD_COLUMN_COUNT } from 'constants/projectCardConst'

function ApolloDndSubscriptionsWrapper(props) {
  const { children } = props
  const { setDndState } = useContext(DndStateContext)

  useSubscription(STAFF_REPLACED, {
    onSubscriptionData: data => {
      const res = data.subscriptionData.data.staffReplaced?.projectStaffs

      if (!res) return

      const newProjectStaffData = res[0]
      const oldProjectStaffData = res[1]

      const movedStaff = {
        id: newProjectStaffData?.staffId,
        staff: newProjectStaffData?.staff
      }

      if (!oldProjectStaffData) {
        setDndState(draft => {
          const newProjectIndex = draft.findIndex(
            project => project.id === newProjectStaffData.projectId
          )
          const newProject = draft[newProjectIndex].projectConnection

          const staffProject = findInProjectStaffData(
            newProject,
            newProjectStaffData
          )

          if (!staffProject) {
            insertStaffInProject(newProject, newProjectIndex, movedStaff, draft)
          }

          const updatedNewProject = updateProject(
            newProject,
            newProjectStaffData
          )
          draft[newProjectIndex].projectConnection = updatedNewProject
          draft[newProjectIndex].rate = newProjectStaffData.project.rate
        })
        return
      }

      setDndState(draft => {
        const newProjectIndex = draft.findIndex(
          project => project.id === newProjectStaffData.projectId
        )
        const oldProjectIndex = draft.findIndex(
          project => project.id === oldProjectStaffData.projectId
        )
        const newProject = draft[newProjectIndex].projectConnection

        let updatedNewProject

        const staffOldProject = findInProjectStaffData(
          draft[oldProjectIndex].projectConnection,
          newProjectStaffData
        )

        if (staffOldProject) {
          staffOldProject.rateStaff = newProjectStaffData.rateStaff

          const updatedOldProject = draft[
            oldProjectIndex
          ].projectConnection.filter(
            staff => staff.staff?.id !== newProjectStaffData.staffId
          )

          draft[oldProjectIndex].projectConnection = updatedOldProject
          updatedNewProject = newProject

          insertStaffInProject(
            updatedNewProject,
            newProjectIndex,
            staffOldProject,
            draft
          )
        } else {
          updatedNewProject = updateProject(newProject, newProjectStaffData)
        }

        draft[newProjectIndex].projectConnection = updatedNewProject
        draft[newProjectIndex].rate = newProjectStaffData.project.rate
        draft[oldProjectIndex].rate = oldProjectStaffData.project.rate
      })
    }
  })

  useSubscription(STAFF_ADDED_BY_PROJECT, {
    onSubscriptionData: data => {
      const res = data.subscriptionData.data.staffAddedByProject?.projectStaff
      if (!res) return
      setDndState(draft => {
        const index = projectIndex(
          data.subscriptionData,
          'staffAddedByProject',
          draft
        )

        const foundedStaff = draft[index].projectConnection.find(
          staff => staff.staff?.id === res.staffId
        )

        if (!foundedStaff) {
          const projectWithAddedStaff = draft[index].projectConnection
          insertStaffInProject(projectWithAddedStaff, index, res, draft)
          draft[index].rate = res.project.rate
        }
      })
    }
  })

  useSubscription(STAFF_DELETED_BY_PROJECT, {
    onSubscriptionData: data => {
      const res = data.subscriptionData.data.staffDeletedByProject?.projectStaff
      if (!res) return
      setDndState(draft => {
        const index = projectIndex(
          data.subscriptionData,
          'staffDeletedByProject',
          draft
        )
        const stateWithoutDeletedStaff = draft[index].projectConnection.filter(
          columnItem => columnItem.staff?.id !== res.staffId
        )

        draft[index].projectConnection = stateWithoutDeletedStaff
        draft[index].rate = res.project.rate
      })
    }
  })

  useSubscription(PROJECT_STAFF_UPDATED, {
    onSubscriptionData: data => {
      const res = data.subscriptionData.data.projectStaffUpdated?.projectStaff
      if (!res) return
      setDndState(draft => {
        const projectIndex = draft.findIndex(
          project => project.id === res.projectId
        )
        const projectWithUpdatedStaffRate = draft[
          projectIndex
        ].projectConnection.map(staff => {
          if (staff.staff?.id === res.staffId) {
            return {
              ...staff,
              rateStaff: res.rateStaff,
              employmentPercentage: res.employmentPercentage
            }
          } else {
            return staff
          }
        })

        draft[projectIndex].projectConnection = projectWithUpdatedStaffRate
        draft[projectIndex].rate = res.project.rate
      })
    }
  })

  useSubscription(ARCHIVE_STAFF_BY_PROJECT_DELETED, {
    onSubscriptionData: data => {
      const res =
        data.subscriptionData.data.archiveStaffByProjectDeleted?.projectStaffs
      if (!res) return
      setDndState(draft => {
        return (draft = draft.map(project => {
          const findStaff = res.find(item => item.projectId === project.id)
          if (!findStaff) {
            return project
          }
          const projectWithArchivedStaff = {
            ...project,
            rate: findStaff.project.rate,
            projectConnection: project.projectConnection.filter(
              item => item.staff?.id !== res[0].staffId
            )
          }
          return (draft = projectWithArchivedStaff)
        }))
      })
    }
  })

  useSubscription(STAFF_UPDATED, {
    onSubscriptionData: data => {
      const res = data.subscriptionData.data.staffUpdated?.staff
      if (!res) return
      setDndState(draft => {
        draft.map(project => {
          const projectWithUpdatedStaff = {
            ...project,
            projectConnection: project.projectConnection.map(item => {
              if (item.staff?.id === res.id) {
                return (item.staff = res)
              } else {
                return item
              }
            })
          }
          return (draft = projectWithUpdatedStaff)
        })
      })
    }
  })

  useSubscription(PROJECT_UPDATED, {
    onSubscriptionData: data => {
      const res = data.subscriptionData.data.projectUpdated?.project
      if (!res) return
      setDndState(draft => {
        return (draft = draft.map(project => {
          if (project.id === res.id) {
            return {
              ...project,
              name: res.name,
              photoURL: res.photoURL,
              description: res.description
            }
          } else {
            return project
          }
        }))
      })
    }
  })

  useSubscription(PROJECT_ADDED, {
    onSubscriptionData: data => {
      const res = data.subscriptionData.data.projectAdded?.project
      if (!res) return
      setDndState(draft => {
        const findProject = draft.find(project => project.id === res.id)
        if (!findProject) {
          draft.push({
            ...res,
            projectConnection: Array(BOARD_COLUMN_COUNT).fill([{}]),
            isVisible: true
          })
        }
      })
    }
  })

  useSubscription(PROJECT_ARCHIVED, {
    onSubscriptionData: data => {
      const res = data.subscriptionData.data.projectArchived.project
      if (!res) return
      setDndState(draft => {
        if (!res.archive) {
          const archiveProject = draft.find(project => project.id === res.id)
          if (archiveProject) {
            return (draft = draft.filter(item => item.id !== archiveProject.id))
          }
        } else {
          const findProject = draft.find(project => project.id === res.id)
          if (!findProject) {
            draft.push({
              ...res,
              projectConnection: Array(BOARD_COLUMN_COUNT).fill([{}]),
              rate: 0,
              isVisible: true
            })
          }
        }
      })
    }
  })

  useSubscription(RENAMED_PROJECT_PORTFOLIO, {
    onSubscriptionData: data => {
      const res = data.subscriptionData.data.renamedPortfolioProject.project
      if (!res) return
      setDndState(draft => draft.map(d => d.id === res.id ? { ...d, name: res.name } : d))
    }
  })

  return <>{children}</>
}

export default ApolloDndSubscriptionsWrapper
