import React from 'react'
import { useMutation } from '@apollo/client'
import EditProjectStaffForm from 'components/EditProjectStaffForm'
import CancelButton from 'components/CancelButton'
import {
  REPLACE_STAFF_FROM_PROJECT,
  MOVE_STAFF_FROM_VIRTUAL_PROJECT,
  GET_PROJECTS
} from 'api/project'
import { AVAILABLE_STAFF } from 'api/staff'

function EditRateModal(props) {
  const {
    closeModal,
    editProjectStaffRateInfo: { staffId, projectId, oldProjectId }
  } = props

  const [moveStaffMutation] = useMutation(
    oldProjectId ? REPLACE_STAFF_FROM_PROJECT : MOVE_STAFF_FROM_VIRTUAL_PROJECT,
    {
      refetchQueries: [{ query: GET_PROJECTS }, { query: AVAILABLE_STAFF }]
    }
  )

  const handleClick = ({ rate, employmentPercentage }) => {
    moveStaffMutation({
      variables: oldProjectId
        ? {
            rateStaff: rate,
            employmentPercentage: employmentPercentage,
            staffId,
            oldProjectId,
            newProjectId: projectId
          }
        : {
            employmentPercentage: +employmentPercentage,
            rateStaff: +rate,
            staffId,
            projectId
          }
    })
    closeModal()
  }

  return (
    <>
      <CancelButton closeModal={closeModal} />
      <EditProjectStaffForm
        detailsTypes={['rateStaff', 'employmentPercentage']}
        detailsData={{ projectId, oldProjectId }}
        staffId={staffId}
        handleClick={handleClick}
        replacedStaff={true}
      />
    </>
  )
}

export default EditRateModal
