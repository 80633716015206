import React, { useState } from 'react'
import { Form } from 'react-final-form'
import { useMutation } from '@apollo/client'
import { ADD_PROJECT } from 'api/project'
import { ProjectForm } from 'components/UI/ProjectForm'
import { message } from 'antd'
import { useTranslation } from 'react-i18next'

function ProjectCardFieldForm({ closeModal }) {
  const [addProject, { loading }] = useMutation(ADD_PROJECT)
  const { t } = useTranslation('ProjectCard')
  const [state, setState] = useState({ photo: '' })
  const onSubmit = ({ name, description }) => {
    addProject({
      variables: {
        name: name.trim(),
        photo: state.photo,
        description: description?.trim()
      }
    }).then(response => {
      closeModal()

      const data = response.data.addProject

      if (data.project) message.success(t('AddProjectSuccess'))
      else {
        const errorCode = data.errors[0].statusCode

        if (errorCode === '409') return message.error(t('ProjectAlreadyExists'))
        message.error(t('ProjectError'))
      }
    })
  }

  const getPhotoFile = photo => {
    setState({
      photo: photo
    })
  }

  return (
    <>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <ProjectForm
            getPhotoFile={getPhotoFile}
            handleSubmit={handleSubmit}
            loading={loading}
          />
        )}
      />
    </>
  )
}

export default ProjectCardFieldForm
