import { useMutation } from '@apollo/client'
import {
  ARCH_STAFF,
  STAFFS,
  STAFFS_IN_ARCHIVE,
  AVAILABLE_STAFF
} from 'api/staff'

function useArchiveStaffMutation(isArchived, currentStaffId) {
  const [archStaff] = useMutation(ARCH_STAFF, {
    variables: { id: currentStaffId, archived: isArchived },
    refetchQueries: [
      { query: STAFFS },
      { query: STAFFS_IN_ARCHIVE },
      { query: AVAILABLE_STAFF }
    ]
  })
  return archStaff
}

export default useArchiveStaffMutation
