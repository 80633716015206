import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { Avatar, FieldLine, ControlButtons, Container } from './styles'
import { Tooltip } from 'antd'
import { BarsOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import CancelButton from '../CancelButton'
import { PROJECT } from 'api/project'
import Modal from 'components/Modal'
import ProjectCardStaffDetails from './components/ProjectCardStaffDetails'
import ProjectMenuDetails from './components/ProjectMenuDetails'
import AddStaffInProject from 'components/AddStaffInProject'
import { AVAILABLE_STAFF } from 'api/staff'
import emptyProject from 'images/project.svg'

function CurrentProjectCard(props) {
  const { t } = useTranslation(['CurrentProjectCard', 'Error'])
  const { closeModal, currentProjectId, rateForProject } = props

  const { loading, error, data } = useQuery(PROJECT, {
    variables: { id: currentProjectId }
  })

  const { data: availableStaffData, refetch } = useQuery(AVAILABLE_STAFF)

  const [
    staffsOnProjectDetailsIsOpen,
    setStaffsOnProjectDetailsIsOpen
  ] = useState(false)
  const [menuProjectIsOpen, setMenuProjectIsOpen] = useState(false)
  const [showAddStaffModal, setShowAddStaffModal] = useState(false)

  const toggleShowStaffsModal = () => {
    refetch()
    setStaffsOnProjectDetailsIsOpen(!staffsOnProjectDetailsIsOpen)
  }

  const toggleShowAddStaffModal = () => {
    refetch()
    setShowAddStaffModal(!showAddStaffModal)
  }

  const toggleShowMenuModal = () => {
    refetch()
    setMenuProjectIsOpen(!menuProjectIsOpen)
  }

  if (loading) return <p>{t('Loading')}</p>
  if (error) return <p>{t('Error')}</p>

  const project = data.project.project
  const { name, description, photoURL } = project

  return (
    <Container>
      <ControlButtons>
        <Tooltip placement="topLeft" title={t('OpenMenu')}>
          <BarsOutlined id="menu" onClick={toggleShowMenuModal} />
        </Tooltip>
        <CancelButton closeModal={closeModal} />
      </ControlButtons>
      <div>
        <FieldLine>
          <Avatar photoURL={photoURL || emptyProject} />
        </FieldLine>
        <FieldLine>
          {t('Name')} {name}
        </FieldLine>
        <FieldLine>
          {t('Description')} {description}
        </FieldLine>
        <FieldLine>
          {t('ProjectRate')}: {rateForProject}
        </FieldLine>
      </div>
      <Modal showModal={menuProjectIsOpen} closeModal={toggleShowMenuModal}>
        <ProjectMenuDetails
          toggleShowMenuModal={toggleShowMenuModal}
          toggleShowStaffsModal={toggleShowStaffsModal}
          projectId={currentProjectId}
          toggleShowAddStaffModal={toggleShowAddStaffModal}
        />
      </Modal>
      <Modal
        showModal={staffsOnProjectDetailsIsOpen}
        closeModal={toggleShowStaffsModal}
      >
        <ProjectCardStaffDetails
          closeStaffModal={toggleShowStaffsModal}
          projectId={currentProjectId}
          toggleShowAddStaffModal={toggleShowAddStaffModal}
        />
      </Modal>
      <Modal showModal={showAddStaffModal} closeModal={toggleShowAddStaffModal}>
        <AddStaffInProject
          availableStaff={availableStaffData.availableStaff.staffs}
          closeModal={toggleShowAddStaffModal}
          currentProjectId={currentProjectId}
          refetch={refetch}
        />
      </Modal>
    </Container>
  )
}

export default CurrentProjectCard
